import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "cms/components/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import Select from "cms/components/Select";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "cms/back-office/components/TabPanel";
import IconLabel from "cms/back-office/components/IconLabel";
import { ModalActions } from "cms/back-office/components/Modal";
import FilePicker from "cms/back-office/components/FilePicker";
import SelectPage from "cms/back-office/components/SelectPage";
import versionStatuses from "cms/enums/versionStatuses";
import AdminPageVersionsService from "cms/back-office/services/AdminPageVersionsService";
import { generateContentId, getDefaultContentByType, enhanceContent } from "cms/back-office/utils/adminContentsUtils";
import { stringToPath } from "cms/utils/urlUtils";
import { debounce, descriptionToolbarEditor } from "cms/utils/commonUtils";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import Image, { ImageContainer } from "cms/editableComponents/Image";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";
import contentsTypes from "cms/enums/contentsTypes";
import { pageImageFiltersKey } from "cms/utils/imageFiltersUtil";
import CKEditorCustom from "cms/back-office/components/CKEditorCustom";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";

const defaultForm = {
  path: "/",
  shortDescription: "",
  contents: [],
};

const FormBlock = ({ children }) => <Box mb={2}>{children}</Box>;
FormBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

const forbiddenPathes = [
  "/health-check",
  "/search",
  "/getPageInfos",
  "/findPagesByTemplates",
  "/menus",
  "/footer",
  "/header",
  "/refresh",
  "/environment.json",
  "/sitemap.xml",
  "/robots.txt",
  "/captcha/antibotInfo",
  "/captcha/antibotToken",
];

const DESCRIPTION_MIN_CHARACTER = 50;
const DESCRIPTION_MAX_CHARACTER = 160;

const AdminPageForm = (props) => {
  const { version, onValidate, onCancel } = props;

  const templates = React.useMemo(() => CmsHooks.getHook(HOOKS.TEMPLATES) || [], []);
  const excludedTemplatesForPageCreation = React.useMemo(
    () => CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageCreation) || [],
    [],
  );
  const appRoutes = React.useMemo(() => (CmsHooks.getHook(HOOKS.App_routes) || []).map((route) => route.path), []);
  const forbiddenPathesFromHook = React.useMemo(() => CmsHooks.getHook(HOOKS.AdminPageForm_ProtectedPaths) || [], []);

  const isNewPage = !version;

  const [isTemplateStep, SetIsTemplateStep] = useState(isNewPage);
  const [form, setForm] = useState(version || defaultForm);
  const [newTag, setNewTag] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pathIsUnavailable, setPathIsUnavailable] = useState(false);

  const { setPageChanges, currentSite } = useContext(AdminWebsiteContext);

  const imageFiltersChild = React.useMemo(() => {
    const { contents } = form;
    const filterChild = contents.find((child) => child.key === pageImageFiltersKey);
    return filterChild
      ? enhanceContent(filterChild)
      : generateContentId({ ...getDefaultContentByType(contentsTypes.IMAGE_FILTERS), key: pageImageFiltersKey });
  }, [form]);

  const errors = React.useMemo(() => {
    const { page, template, title, path, image } = form;
    const { parentId, name, id } = page || {};
    const isHomePage = id && !parentId && path === "/";
    let pathError = null;
    if (path === "/" && !isHomePage) {
      pathError = "Veuillez saisir un chemin pour cette page";
    }
    if ([...forbiddenPathes, ...forbiddenPathesFromHook, ...appRoutes].find((p) => p === path)) {
      pathError = "Ce chemin n'est pas autorisé";
    }
    if (pathIsUnavailable) {
      pathError = "Ce chemin est déjà utilisé dans une page publiée";
    }
    return {
      parentId: !parentId && !isHomePage,
      template: !template,
      name: !name,
      title: !title,
      path: pathError,
      image: (image && !image.title) || (image && !image.alt),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRoutes, forbiddenPathesFromHook, form, pathIsUnavailable, formSubmitted]);

  const checkIfPathIsAvailable = debounce(
    (path, pageId) =>
      AdminPageVersionsService.find({
        params: {
          join: ["page"],
          filter: [
            `path||eq||${path}`,
            `page.siteId||eq||${currentSite.id}`,
            `page.id||ne||${pageId}`,
            `status||eq||${versionStatuses.PUBLISHED}`,
          ],
        },
      }).then((versions) => {
        if (versions?.length > 0) {
          setPathIsUnavailable(true);
        } else {
          setPathIsUnavailable(false);
        }
      }),
    500,
  );

  React.useEffect(() => {
    if (!errors.path) {
      checkIfPathIsAvailable(form.path, version?.page?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.path, form.path, version?.page?.id]);

  const allTemplates = isNewPage ? templates.filter((t) => !excludedTemplatesForPageCreation.includes(t)) : templates;

  const { path, template, title, shortDescription, description, tags, image, page = {} } = form || {};

  const characterCountDescription = (description && description.length) || 0;

  const { name, parentId } = page;

  const validateText = isNewPage ? "Créer" : "Mettre à jour";

  const handleFormChange = (e) => {
    const { value, name: fieldName } = e.target;

    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleFormPageChange = (e) => {
    const { value, name: fieldName } = e.target;
    setForm({
      ...form,
      page: {
        ...form.page,
        [fieldName]: value,
      },
    });
  };

  const handleChangeTitle = (e) => {
    const { value } = e.target;
    const pathFromTitle = stringToPath(value);
    setForm({
      ...form,
      path: form.path === stringToPath(form.title) ? pathFromTitle : form.path,
      title: value,
    });
  };

  const handleChangePath = (e) => {
    const { value } = e.target;
    setPathIsUnavailable(false);
    setForm({
      ...form,
      path: stringToPath(value),
    });
  };

  const handleValidate = () => {
    setFormSubmitted(true);
    if (Object.values(errors).reduce((a, b) => a && !b, true)) {
      if (typeof onValidate === "function") {
        setPageChanges([]);
        onValidate(form);
      }
    }
  };

  const getTemplateByKey = (templateKey) => {
    return templates.find((t) => t.key === templateKey);
  };

  const handleTemplateChange = (templateDefinition) => {
    const { key, initialContents = [] } = templateDefinition;

    setForm({
      ...form,
      template: key,
      contents: initialContents.map(generateContentId),
    });
  };

  const handleSelectParentPage = (parentPage) => {
    setForm({
      ...form,
      page: {
        ...form.page,
        parentId: parentPage.id,
      },
    });
  };

  const handleChangeImage = (newImage = {}) => {
    if (!newImage) {
      setForm({
        ...form,
        image: null,
      });
    } else {
      const { filters, ...others } = newImage;

      let newContents = form?.contents || [];

      if (filters) {
        let hasFilters = false;
        newContents = newContents.map((c) => {
          if (c.key === pageImageFiltersKey) {
            hasFilters = true;
            return filters;
          }
          return c;
        });
        if (!hasFilters) {
          newContents.push(filters);
        }
      } else {
        newContents = newContents.filter((c) => c.key !== pageImageFiltersKey);
      }

      setForm({
        ...form,
        contents: newContents,
        image: newImage
          ? {
              ...image,
              ...others,
            }
          : null,
      });
    }
  };

  const handleShortDescriptionChange = (value) => {
    setForm({
      ...form,
      shortDescription: value,
    });
  };

  const handleSubmitFormTags = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    let newTags = [];
    if (tags && Array.isArray(tags)) {
      newTags = tags;
    }

    const tag = newTag.trim();
    if (tag.length > 0) {
      if (newTags.find((t) => t === newTag) === undefined) {
        newTags.push(tag);
        setForm({
          ...form,
          tags: newTags,
        });
        setNewTag("");
      }
    }
  };

  const handleClickDeleteChip = (tag) => () => {
    const tagsList = tags.filter((t) => t !== tag);

    setForm({
      ...form,
      tags: tagsList,
    });
  };

  const handleTagChange = (e) => {
    const { value } = e.target;

    setNewTag(value);
  };

  const canUpdateTemplate = isNewPage;
  const canUpdateParent = isNewPage;

  const showError = (key) => formSubmitted && errors[key];

  const TemplateSelector = () => (
    <>
      <Typography
        sx={{
          fontWeight: 600,
          color: "form.text",
          mb: 1,
        }}
        variant="subtitle1"
      >
        Choisissez un gabarit de page
      </Typography>
      <Grid container spacing={1}>
        {allTemplates.map((t) => (
          <Grid key={t.key} item xs={3}>
            <Stack
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderColor: "layout.templateBorder",
                borderRadius: "5px",
                overflow: "hidden",
                opacity: template === t.key ? 0.6 : 1,
                "&:hover": {
                  opacity: 0.6,
                },
              }}
              onClick={() => {
                SetIsTemplateStep(false);
                handleTemplateChange(t);
              }}
            >
              <ImageContainer ratio={50}>
                <Image
                  file={{ url: t.templateImage || "/default-image.jpg" }}
                  alt={t.key}
                  position={t.templateImage ? "top" : "center"}
                  disableLazy
                />
              </ImageContainer>
              <Box
                sx={{
                  bgcolor: "layout.template",
                  color: "secondary.text",
                  p: 2,
                  fontWeight: "bold",
                }}
              >
                {t.label}
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: "100%" }}>
      {isTemplateStep ? (
        <TemplateSelector />
      ) : (
        <>
          <Box>
            <Tabs
              variant="scrollable"
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              sx={{ mt: 1, mb: 4 }}
            >
              <Tab label={<IconLabel icon="file-alt" title="Informations" />} {...a11yProps(0)} />
              <Tab label={<IconLabel icon="image" title="Image" />} {...a11yProps(1)} />
              {template && getTemplateByKey(template).useTags && (
                <Tab label={<IconLabel icon="globe" title="Tags" />} {...a11yProps(2)} />
              )}
            </Tabs>
            <Box sx={{ height: "50vh", mb: 3, overflowY: "auto", pr: 2 }}>
              <TabPanel value={value} index={0}>
                <FormBlock>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item xs={4}>
                      <FormControl error={showError("parentId")}>
                        <SelectPage
                          currentPage={{ id: parentId }}
                          onSelectPage={handleSelectParentPage}
                          disabled={!canUpdateParent}
                          label="Page Parente"
                          onlyPublishedPages
                        />
                        {showError("parentId") && (
                          <FormHelperText>Veuillez sélectionner une page parente</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl error={showError("template")}>
                        <InputLabel shrink>Gabarit</InputLabel>
                        <Select
                          value={template || ""}
                          name="template"
                          onChange={(e) => handleTemplateChange(getTemplateByKey(e.target.value))}
                          disabled={!canUpdateTemplate}
                        >
                          {allTemplates.map((t) => (
                            <MenuItem key={t.key} value={t.key}>
                              {t.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {showError("template") && <FormHelperText>Veuillez sélectionner un gabarit</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        error={showError("name")}
                        label="Nom (uniquement pour l'administration)"
                        value={name}
                        name="name"
                        onChange={handleFormPageChange}
                        helperText={showError("name") && "Veuillez nommer cette page"}
                      />
                    </Grid>
                  </Grid>
                </FormBlock>
                <FormBlock>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        error={showError("title")}
                        label="Titre de la page"
                        value={title}
                        name="title"
                        onChange={handleChangeTitle}
                        helperText={showError("title") && "Veuillez donner un titre à cette page"}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={formSubmitted && Boolean(errors.path)}
                        label="Chemin"
                        value={path}
                        name="path"
                        onChange={handleChangePath}
                        helperText={formSubmitted && errors.path}
                      />
                    </Grid>
                  </Grid>
                </FormBlock>
                <FormBlock>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box mt={1}>
                        <FormControl>
                          <InputLabel sx={formStyled.labelWithTooltip} shrink>
                            Description courte
                            <Tooltip title="Utilisé comme chapeau par certains gabarits ou lorsque ces données sont insérées en tant que sous-page">
                              <HelpIcon />
                            </Tooltip>
                          </InputLabel>
                          <CKEditorCustom
                            key={page.id}
                            initData={decodeURIComponent(shortDescription || "")}
                            onChange={(data) => handleShortDescriptionChange(encodeURIComponent(data))}
                            type="inline"
                            config={{ toolbar: descriptionToolbarEditor, baseFloatZIndex: 1350 }}
                            debounce={0}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </FormBlock>
                <FormBlock>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box mt={1}>
                        <TextField
                          label={`Description de la page (meta-donnée de référencement). ${DESCRIPTION_MIN_CHARACTER} caractères minimum recommandés.`}
                          value={description || ""}
                          name="description"
                          onChange={handleFormChange}
                          multiline
                          rows={5}
                          inputProps={{ maxLength: DESCRIPTION_MAX_CHARACTER }}
                        />
                        <Box
                          sx={{ mt: 1 }}
                        >{`Caractères : ${characterCountDescription}/${DESCRIPTION_MAX_CHARACTER}`}</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </FormBlock>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FormBlock>
                  <Box mb={1}>
                    <label sx={{ color: "secondary.text", fontWeight: "bold" }}>Image de la page</label>
                  </Box>
                  <FilePicker
                    onSelectFile={handleChangeImage}
                    file={image}
                    pageVersion={version}
                    image
                    formSubmitted={formSubmitted}
                    imageFiltersChild={imageFiltersChild}
                  />
                </FormBlock>
              </TabPanel>
              {template && getTemplateByKey(template).useTags && (
                <TabPanel value={value} index={2}>
                  <FormBlock>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          "& > fieldset .cke_editable": {
                            minHeight: 100,
                          },
                        }}
                      >
                        <fieldset>
                          <legend>Tags</legend>
                          <form onSubmit={handleSubmitFormTags}>
                            {tags &&
                              tags.map((tag) => (
                                <Chip
                                  key={tags.indexOf(tag)}
                                  label={tag}
                                  sx={{ m: 1 }}
                                  onDelete={handleClickDeleteChip(tag)}
                                  color="primary"
                                />
                              ))}
                            <TextField
                              placeholder="Ajouter un tag"
                              value={newTag}
                              onChange={handleTagChange}
                              error={showError("duplicateTag")}
                              helperText={showError("duplicateTag") && "Ce tag est déjà renseigné"}
                              variant="standard"
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    color="primary"
                                    sx={{
                                      borderRadius: "0 7px 7px 0",
                                      bgcolor: "form.textFieldButtonBackground",
                                      color: "white",
                                      "&:hover": {
                                        bgcolor: "form.textFieldButtonBackground",
                                      },
                                    }}
                                    onClick={handleSubmitFormTags}
                                    size="large"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </form>
                        </fieldset>
                      </Grid>
                    </Grid>
                  </FormBlock>
                </TabPanel>
              )}
            </Box>
          </Box>
          <ModalActions>
            {canUpdateTemplate && (
              <Button
                sx={{
                  position: "absolute",
                  left: 0,
                }}
                color="secondary"
                variant="text"
                onClick={() => SetIsTemplateStep(true)}
              >
                Précédent
              </Button>
            )}
            <Button color="secondary" onClick={onCancel}>
              Annuler
            </Button>
            <Button color="primary" onClick={handleValidate}>
              {validateText}
            </Button>
          </ModalActions>
        </>
      )}
    </Box>
  );
};

AdminPageForm.propTypes = {
  version: PropTypes.shape(),
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AdminPageForm.defaultProps = {
  version: null,
};

export default AdminPageForm;
