import React from "react";
import PropTypes from "prop-types";
import AdminMenuItems from "cms/back-office/components/AdminMenuItems";

const AdminMenuForm = (props) => {
  const { menu, onMenuChange, canUpdate } = props;
  const { contents = [] } = menu;

  const handleItemsChange = (menuItems) => {
    onMenuChange({
      ...menu,
      contents: menuItems,
    });
  };

  return (
    <div>
      <AdminMenuItems
        items={contents}
        itemKey="menus"
        subItemKey="subMenus"
        onItemsChange={handleItemsChange}
        maxDepth={1}
        canUpdate={canUpdate}
      />
    </div>
  );
};

AdminMenuForm.propTypes = {
  menu: PropTypes.shape(),
  onMenuChange: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool,
};

AdminMenuForm.defaultProps = {
  menu: {},
  canUpdate: false,
};

export default AdminMenuForm;
