import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

const AdminVersionsItemAction = (props) => {
  const { selected, ...others } = props;

  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "60px",
        height: "100%",
        borderRight: "1px solid",
        borderColor: "layout.border",
        px: 2,
        color: "secondary.text",
        fontWeight: "bold",
        cursor: "pointer",
        bgcolor: selected ? "secondary.main" : "transparent",
        "& > i": {
          fontSize: "1.5rem",
          color: selected && "inherit",
        },
        "&:hover": {
          bgcolor: "secondary.main",
        },
        "@media screen and (max-width: 1400px)": {
          "& > span": {
            display: "none",
          },
          "& > i": {
            mr: "0 !important",
          },
        },
      }}
      {...others}
    />
  );
};

AdminVersionsItemAction.propTypes = {
  selected: PropTypes.bool,
};

AdminVersionsItemAction.defaultProps = {
  selected: false,
};

export default AdminVersionsItemAction;
