var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "cms/components/TextField";
import AdminPagesService from "cms/back-office/services/AdminPagesService";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
var DEFAULT_INPUT_VALUE = "Aucune";
var per_page = 200;
var SelectPage = function (props) {
    var _a = props.currentPage, currentPage = _a === void 0 ? {} : _a, onSelectPage = props.onSelectPage, canBeEmpty = props.canBeEmpty, disabled = props.disabled, _b = props.label, label = _b === void 0 ? "Sélectionner une page" : _b, onlyPublishedPages = props.onlyPublishedPages;
    var templates = useMemo(function () { return CmsHooks.getHook(HOOKS.TEMPLATES) || []; }, []);
    var _c = useState((currentPage && currentPage.name) || (canBeEmpty ? DEFAULT_INPUT_VALUE : "")), inputValue = _c[0], setInputValue = _c[1];
    var _d = useContext(AdminWebsiteContext).currentSite, currentSite = _d === void 0 ? {} : _d;
    var _e = useQuery(["allPages", currentSite === null || currentSite === void 0 ? void 0 : currentSite.id], function () { return __awaiter(void 0, void 0, void 0, function () {
        var allPages, loadPages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    allPages = [];
                    loadPages = function (offset) {
                        if (offset === void 0) { offset = 0; }
                        return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2 /*return*/, AdminPagesService.find({
                                        params: {
                                            filter: ["siteId||eq||".concat(currentSite === null || currentSite === void 0 ? void 0 : currentSite.id)],
                                            join: ["versions"],
                                            sort: "name,ASC",
                                            offset: offset,
                                            per_page: per_page,
                                        },
                                    }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                                        var total, data;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    total = response.total, data = response.data;
                                                    allPages = allPages.concat(data);
                                                    if (!(total > offset + per_page)) return [3 /*break*/, 2];
                                                    return [4 /*yield*/, loadPages(offset + per_page)];
                                                case 1:
                                                    _a.sent();
                                                    _a.label = 2;
                                                case 2: return [2 /*return*/];
                                            }
                                        });
                                    }); })];
                            });
                        });
                    };
                    return [4 /*yield*/, loadPages()];
                case 1:
                    _a.sent();
                    return [2 /*return*/, allPages];
            }
        });
    }); }, {
        enabled: Boolean(currentSite && currentSite.id),
    }).data, pagesResponse = _e === void 0 ? [] : _e;
    var pages = useMemo(function () {
        var filteredPages = pagesResponse
            .filter(function (page) { return page.versions && page.versions.length > 0 && (!onlyPublishedPages || page.isPublished); })
            .map(function (page) { return (__assign(__assign({}, page), { templateLabel: templates.find(function (template) { return template.key === page.versions[0].template; }).label })); })
            .sort(function (a, b) { return a.templateLabel.localeCompare(b.templateLabel); });
        if (canBeEmpty) {
            filteredPages.unshift({
                id: "",
                name: DEFAULT_INPUT_VALUE,
                versions: [{}],
            });
        }
        return filteredPages;
    }, [canBeEmpty, onlyPublishedPages, pagesResponse, templates]);
    var getPage = function () {
        if (inputValue === DEFAULT_INPUT_VALUE && !currentPage && canBeEmpty) {
            return {
                id: "",
                name: inputValue,
                versions: [{}],
            };
        }
        if (currentPage && !currentPage.name && currentPage.id) {
            return pages.find(function (page) { return page.id === currentPage.id; });
        }
        return currentPage;
    };
    var handleSelectPageChange = function (e, value) {
        if (value && !(value instanceof String)) {
            var id_1 = value.id;
            var page = pages.find(function (p) { return p.id === id_1; });
            onSelectPage(page);
            setInputValue(page === null || page === void 0 ? void 0 : page.name);
        }
        else {
            setInputValue(value || "");
        }
    };
    return (_jsx(Autocomplete, { value: getPage() || null, inputValue: inputValue, options: pages, getOptionLabel: function (option) { return (option && option.name) || ""; }, noOptionsText: "Aucune page correspondante", groupBy: function (option) { return option && option.templateLabel; }, onChange: handleSelectPageChange, onInputChange: function (e, value) { return setInputValue(value); }, disabled: disabled, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { InputLabelProps: {
                shrink: true,
            }, label: label, name: "page", placeholder: "-- S\u00E9lectionner une page --", disabled: disabled }))); } }));
};
export default SelectPage;
