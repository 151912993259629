import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { createBackOfficeTheme, getCurrentThemeType, saveBackOfficeTheme } from "cms/back-office/theme/themeBackOffice";

export const ThemeContext = createContext({ setThemeType: () => null, themeBackOffice: null });

const ThemeBackOfficeProvider = (props) => {
  const { children } = props;

  const [themeType, setThemeType] = useState(getCurrentThemeType());
  const [theme, setTheme] = useState(createBackOfficeTheme(themeType));

  const handleChangeTheme = (type) => {
    saveBackOfficeTheme(type);
    setTheme(createBackOfficeTheme(type));
    setThemeType(type);
  };

  return (
    <ThemeContext.Provider value={{ setThemeType: handleChangeTheme, themeBackOffice: theme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};

ThemeBackOfficeProvider.propTypes = {
  children: PropTypes.node,
};

ThemeBackOfficeProvider.defaultProps = {
  children: null,
};

export default ThemeBackOfficeProvider;
