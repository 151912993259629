import { createContext } from "react";

export const defaultContext = {
  buttonLabel: "Gérer des groupes de contenus",
  title: "Gérer des groupes de contenus",
  subTitle: "",
  types: [],
  getLabelFromComponent: (component) => component.key,
  getLabelFromContent: (content) => content.key,
};

export default createContext(defaultContext);
