import React, { Fragment, useState, useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import versionStatuses from "cms/enums/versionStatuses";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";
import ModalConfirm from "cms/back-office/components/ModalConfirm";
import PreventNavigation from "cms/back-office/components/PreventNavigation";
import RenameVersionForm from "cms/back-office/components/RenameVersionForm";
import SelectVersion from "cms/back-office/components/SelectVersion";
import Icon from "cms/editableComponents/Icon";
import Loader from "cms/back-office/components/Loader";
import Modal from "cms/back-office/components/Modal";
import AdminSitesService from "cms/back-office/services/AdminSitesService";
import AdminContext from "cms/back-office/components/AdminContext";
import PagesTable from "cms/back-office/components/PagesTable";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("FR-fr", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

const statusLabelStyled = {
  display: "inline-flex",
  columnGap: "5px",
  fontSize: "1.2rem",
  color: "secondary.text",
  fontWeight: "bold",
};

const versionLabelStyled = { fontSize: "0.9rem", color: "layout.draft" };

const DefaultMessage = (props) => {
  const { title, version } = props;
  const { status } = version || {};

  const getPageName = () => {
    const name = version.page ? version.page.name : title;

    return (
      <>
        <Box
          sx={{
            overflow: "hidden",
            maxWidth: 200,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "inline-block",
          }}
          title={name}
        >
          {name}
        </Box>
        <Box component="span" sx={{ color: "layout.border" }}>
          /
        </Box>
      </>
    );
  };

  switch (status) {
    case versionStatuses.DRAFT:
      return (
        <Grid container direction="column" spacing={1}>
          <Grid item sx={statusLabelStyled}>
            {getPageName()}
            <Box component="span" sx={{ color: "layout.draft" }}>
              Brouillon
            </Box>
          </Grid>
          <Grid item sx={versionLabelStyled}>
            Dernière mise à jour le {formatDate(version.updated)}
          </Grid>
        </Grid>
      );
    case versionStatuses.ARCHIVED:
      return (
        <Grid container direction="column" spacing={1}>
          <Grid item sx={statusLabelStyled}>
            {getPageName()}
            <Box component="span" sx={{ color: "unpublished.textStatus" }}>
              Version archivée
            </Box>
          </Grid>
          <Grid item sx={versionLabelStyled}>
            Archivée le {formatDate(version.updated)}
          </Grid>
        </Grid>
      );
    case versionStatuses.PUBLISHED:
      return (
        <Grid container direction="column" spacing={1}>
          <Grid item sx={statusLabelStyled}>
            {getPageName()}
            <Box component="span" sx={{ color: "success.main" }}>
              Version publiée
            </Box>
          </Grid>
          <Grid item sx={versionLabelStyled}>
            Publiée le {formatDate(version.updated)}
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

DefaultMessage.propTypes = {
  title: PropTypes.string,
  version: PropTypes.shape(),
};

DefaultMessage.defaultProps = {
  title: null,
  version: null,
};

const AdminVersions = (props) => {
  const {
    showVersion,
    leftActions,
    rightActions,
    versions,
    currentVersion,
    setCurrentVersion,
    onVersionChange,
    canRename: canRenameProp,
    saveVersion,
    canPublish: canPublishProp,
    canUnpublish: canUnpublishProp,
    updateStatus,
    canDelete: canDeleteProp,
    deleteVersion,
    children,
    autoSaveDelay,
    manualSave,
    principal,
    title,
  } = props;

  const { id: currentVersionId } = currentVersion || {};

  const loadingPageVersion = React.useMemo(
    () => !versions.some((v) => v.id === currentVersionId),
    [currentVersionId, versions],
  );

  const {
    currentSite,
    versionIsCanceled,
    editorValueChange,
    setEditorValueChange,
    setVersionIsCanceled,
    pageChanges,
    setPageChanges,
    versionHasChanged,
    setVersionHasChanged,
  } = useContext(AdminWebsiteContext);
  const { setCurrentPage } = useContext(AdminContext);

  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [versionMessage, setVersionMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isPendingVersion, setIsPendingVersion] = useState(false);

  const [modal, setModal] = useState({
    content: null,
    size: null,
  });

  useEffect(() => {
    setVersionHasChanged(false);
    setVersionMessage(null);
    setIsPendingVersion(false);
  }, [currentVersionId, setVersionHasChanged]);

  useEffect(() => {
    if (pageChanges && pageChanges.length === 0) {
      if (!isSaving) {
        setVersionMessage(null);
      }
      setVersionHasChanged(false);
      setIsPendingVersion(false);
      setVersionIsCanceled(true);
    }
  }, [isSaving, pageChanges, setVersionHasChanged, setVersionIsCanceled]);

  const openModal = (content, size = null) => {
    setModal({ content, size });
  };

  const closeModal = () =>
    setModal({
      content: null,
      size: null,
    });

  const handleClickRenameVersion = () => {
    openModal(
      <RenameVersionForm
        versionName={currentVersion.versionName}
        onValidate={(name) => {
          saveVersion({ ...currentVersion, versionName: name }).then(() => {
            setCurrentVersion(currentVersion);
            closeModal();
          });
        }}
        onCancel={closeModal}
      />,
      "sm",
    );
  };

  const handleClickDeleteVersion = () => {
    openModal(
      <ModalConfirm
        title="Supprimer cette version"
        text="Attention cette action est irréversible"
        confirmText="Supprimer"
        confirmButtonProps={{ style: { bgcolor: "error.main" } }}
        onConfirm={() => {
          deleteVersion(currentVersion).then(() => {
            setVersionHasChanged(false);
            setCurrentVersion();
            closeModal();
          });
        }}
        onCancel={closeModal}
      />,
      "sm",
    );
  };

  const updateVersionStatus = ({ version, status }) => {
    AdminSitesService.resetSiteCache(currentSite);
    return updateStatus({ version, status }).then(() => setCurrentVersion(version));
  };

  const handleClickPublish = () => {
    const { status } = currentVersion;
    if (status === versionStatuses.DRAFT) {
      updateVersionStatus({ version: currentVersion, status: versionStatuses.PUBLISHED });
    } else {
      saveVersion(currentVersion).then((newVersion) =>
        updateVersionStatus({ version: newVersion, status: versionStatuses.PUBLISHED }),
      );
    }
  };

  const handleClickUnpublish = () => {
    openModal(
      <ModalConfirm
        title={`Dépublier "${currentVersion.page.name}"`}
        text={
          <Box>
            Attention, si vous dépubliez cette page, les sous-pages liées à celle-ci seront également dépubliées.
            <br />
          </Box>
        }
        detailContent={
          <PagesTable
            onPageClick={(event, page) => {
              setCurrentPage(page);
              closeModal();
            }}
            pageId={currentVersion?.pageId}
            title={`Liste des sous-pages de "${currentVersion.page.name}"`}
          />
        }
        confirmText="Dépublier"
        confirmButtonProps={{
          sx: { bgcolor: "error.main" },
        }}
        onConfirm={() => {
          updateVersionStatus({
            version: currentVersion,
            status: versionStatuses.ARCHIVED,
          });
          closeModal();
        }}
        onCancel={closeModal}
      />,
      "md",
    );
  };

  const handleSaveVersion = useCallback(
    (version, refresh = true) => {
      setIsPendingVersion(false);
      if (manualSave) setPageChanges([]);

      if (!isSaving) {
        if (version.status === versionStatuses.DRAFT) {
          setVersionMessage("Enregistrement...");
        } else {
          setVersionMessage("Création d'un nouveau brouillon...");
        }
        setAutoSaveTimeout(null);
        setIsSaving(true);
        saveVersion(version).then((v) => {
          if (refresh) {
            setCurrentVersion(v).then(() => {
              setIsSaving(false);
              setVersionHasChanged(false);
              setVersionMessage(null);
            });
          } else {
            setIsSaving(false);
            setVersionHasChanged(false);
            setVersionMessage(null);
          }
        });
      }
    },
    [saveVersion, setCurrentVersion, setPageChanges, isSaving, manualSave, setVersionHasChanged],
  );

  const handleVersionChange = useCallback(
    (version) => {
      if (version.id === currentVersionId && (!versionIsCanceled || (versionIsCanceled && !editorValueChange))) {
        if (manualSave) {
          setIsPendingVersion(true);
          setVersionMessage("Modifications en attente");
        } else setVersionMessage("Modifications en cours");

        setVersionHasChanged(true);
        onVersionChange(version);

        if (autoSaveDelay > 0 || !manualSave) {
          if (autoSaveTimeout) {
            clearTimeout(autoSaveTimeout);
          }
          if (autoSaveDelay && autoSaveDelay > 0) {
            const newTimeout = setTimeout(() => {
              handleSaveVersion(version);
            }, autoSaveDelay);
            setAutoSaveTimeout(newTimeout);
          }
        }
      }

      if (versionIsCanceled) setVersionIsCanceled(false);
      if (editorValueChange) setEditorValueChange(false);
    },
    [
      currentVersionId,
      onVersionChange,
      autoSaveTimeout,
      autoSaveDelay,
      handleSaveVersion,
      manualSave,
      versionIsCanceled,
      editorValueChange,
      setVersionIsCanceled,
      setEditorValueChange,
      setVersionHasChanged,
    ],
  );

  const handleShowPrompt = () => {
    clearTimeout(autoSaveTimeout);
  };

  const handleClickNavigate = () => {
    setVersionHasChanged(false);
    setVersionMessage(null);
  };

  const handleCancel = () => {
    setVersionHasChanged(false);
    setVersionMessage(null);
    setIsPendingVersion(false);
    setVersionIsCanceled(true);
    setPageChanges([]);
    setCurrentVersion({
      ...currentVersion,
      contents: pageChanges[0]?.contents || currentVersion.contents,
      title: pageChanges[0]?.title || currentVersion.title,
      shortDescription: pageChanges[0]?.shortDescription || currentVersion?.shortDescription,
    });
  };

  const { status } = currentVersion || {};

  const isDraft = status === versionStatuses.DRAFT;
  const isPublished = status === versionStatuses.PUBLISHED;

  const canRename = canRenameProp && isDraft;
  const canPublish = canPublishProp && typeof updateStatus === "function" && !versionHasChanged && !isPublished;
  const canUnpublish = canUnpublishProp && typeof updateStatus === "function" && isPublished;
  const canDelete = canDeleteProp && typeof deleteVersion === "function" && isDraft && versions.length > 1;

  return (
    <Fragment>
      <PreventNavigation
        blocked={versionHasChanged && !isSaving}
        onSave={() => handleSaveVersion(currentVersion, false)}
        onShowPrompt={handleShowPrompt}
        onNavigate={handleClickNavigate}
      />
      <Modal open={!!modal.content} size={modal.size} onClose={closeModal}>
        {modal.content}
      </Modal>
      <Box sx={{ flexGrow: 1, height: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            position: principal ? "fixed" : "initial",
            top: 0,
            left: 50,
            right: 0,
            height: "80px",
            bgcolor: principal ? "layout.background" : "transparent",
            borderBottom: `1px solid layout.border`,
            borderColor: "layout.border",
            zIndex: 350,
            pr: 4,
            transition: "background-color 1000ms ease, color 1000ms ease, border-color 1000ms ease",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center" sx={{ height: "100%" }}>
            {leftActions && (
              <Grid item sx={{ height: "100%" }}>
                {leftActions}
              </Grid>
            )}
            {showVersion && (
              <Grid item>
                {!loadingPageVersion ? (
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                      {versionMessage ? (
                        <>
                          <Box
                            component="span"
                            sx={{
                              fontSize: isPendingVersion ? "1rem" : "0.9rem",
                              color: isPendingVersion ? "typography.default" : "layout.draft",
                            }}
                          >
                            {versionMessage}
                          </Box>
                          {isPendingVersion && (
                            <Box
                              sx={{
                                mt: 0.5,
                                fontSize: "0.9rem",
                                color: "layout.draft",
                                cursor: "pointer",
                                textAlign: "center",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={handleCancel}
                            >
                              Annuler
                            </Box>
                          )}
                        </>
                      ) : (
                        <DefaultMessage title={title} version={currentVersion} />
                      )}
                    </Grid>

                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <SelectVersion
                            versions={versions}
                            selectedVersion={currentVersion}
                            onSelectVersion={setCurrentVersion}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" spacing={1}>
                            {canRename && (
                              <Grid item>
                                <Icon
                                  icon="edit"
                                  type="fas"
                                  title="Renommer cette version"
                                  onClick={handleClickRenameVersion}
                                  sx={{
                                    fontSize: "0.95rem",
                                    cursor: "pointer",
                                    color: "layout.icon",
                                  }}
                                />
                              </Grid>
                            )}
                            {canDelete && (
                              <Grid item>
                                <Icon
                                  icon="trash"
                                  type="fas"
                                  title="Supprimer cette version"
                                  onClick={handleClickDeleteVersion}
                                  sx={{
                                    fontSize: "0.95rem",
                                    cursor: "pointer",
                                    color: "layout.icon",
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Loader />
                )}
              </Grid>
            )}

            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>{rightActions}</Grid>
                {manualSave && (
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={() => handleSaveVersion(currentVersion)}
                      disabled={!versionHasChanged || isSaving}
                    >
                      Enregistrer
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  {!canUnpublish && canPublishProp && (
                    <Button color="primary" onClick={handleClickPublish} disabled={!canPublish || versionHasChanged}>
                      Publier cette version
                    </Button>
                  )}
                  {canUnpublish && (
                    <Button
                      sx={{
                        color: "unpublished.text",
                        bgcolor: "unpublished.main",
                        "&:hover": {
                          bgcolor: "unpublished.dark",
                        },
                      }}
                      onClick={handleClickUnpublish}
                      disabled={manualSave && isPendingVersion}
                    >
                      Dépublier cette version
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, pt: principal ? "80px" : 0 }}>{children({ handleVersionChange })}</Box>
      </Box>
    </Fragment>
  );
};

const versionShape = {
  status: PropTypes.oneOf(Object.values(versionStatuses)).isRequired,
  updated: PropTypes.string.isRequired,
  versionName: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape()),
};

AdminVersions.propTypes = {
  showVersion: PropTypes.bool,
  leftActions: PropTypes.node,
  rightActions: PropTypes.node,
  versions: PropTypes.arrayOf(PropTypes.shape(versionShape)).isRequired,
  currentVersion: PropTypes.shape(versionShape).isRequired,
  setCurrentVersion: PropTypes.func.isRequired,
  onVersionChange: PropTypes.func.isRequired,
  saveVersion: PropTypes.func.isRequired,
  canRename: PropTypes.bool,
  canPublish: PropTypes.bool,
  canUnpublish: PropTypes.bool,
  updateStatus: PropTypes.func,
  canDelete: PropTypes.bool,
  deleteVersion: PropTypes.func,
  children: PropTypes.func.isRequired,
  autoSaveDelay: PropTypes.number,
  manualSave: PropTypes.bool,
  principal: PropTypes.bool,
  title: PropTypes.string,
};
AdminVersions.defaultProps = {
  showVersion: true,
  leftActions: null,
  rightActions: null,
  canRename: true,
  canPublish: true,
  canUnpublish: true,
  updateStatus: null,
  canDelete: true,
  deleteVersion: null,
  autoSaveDelay: 2000,
  manualSave: false,
  principal: false,
  title: null,
};

export default AdminVersions;
