import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { Box, ClickAwayListener, Divider, Switch, Tooltip } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import Modal from "cms/back-office/components/Modal";
import { getCurrentThemeType } from "cms/back-office/theme/themeBackOffice";
import { ThemeContext } from "cms/back-office/theme/ThemeBackOfficeProvider";
import themeTypes from "cms/back-office/theme/themeTypes";

export const NavigationGroup = {
  name: "navigation",
  order: 1,
};

export const defaultGroups = [NavigationGroup];

export const SideBarContext = React.createContext({});

const initialState = { groups: [], items: [] };

const actionType = {
  addGroups: "addGroups",
  removeGroups: "removeGroups",
  addItems: "addItems",
  removeItems: "removeItems",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionType.addGroups:
      return {
        ...state,
        groups: state.groups.concat(action.groups),
      };
    case actionType.removeGroups:
      return {
        ...state,
        groups: state.groups.filter((group) => !action.groups.includes(group)),
      };
    case actionType.addItems:
      return {
        ...state,
        items: state.items.concat(action.items),
      };
    case actionType.removeItems:
      return {
        ...state,
        items: state.items.filter((item) => !action.items.includes(item)),
      };
    default:
      return state;
  }
};

const defaultModal = {
  content: null,
  size: null,
  title: null,
};

export const SideBarContextProvider = (props) => {
  const { children } = props;
  const { push: historyPush } = useHistory();

  const { keycloak } = useKeycloak();
  const { resourceAccess } = keycloak;

  const isAdmin = React.useMemo(
    () => resourceAccess?.["realm-management"]?.roles?.includes("manage-users"),
    [resourceAccess],
  );

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [modal, setModal] = React.useState(defaultModal);

  const closeModal = React.useCallback(() => {
    setModal(defaultModal);
  }, []);

  const addGroups = React.useCallback((groups) => {
    dispatch({ type: actionType.addGroups, groups });
  }, []);

  const removeGroups = React.useCallback((groups) => {
    dispatch({ type: actionType.removeGroups, groups });
  }, []);

  const addItems = React.useCallback((items) => {
    dispatch({ type: actionType.addItems, items });
  }, []);

  const removeItems = React.useCallback((items) => {
    dispatch({ type: actionType.removeItems, items });
  }, []);

  const [isExpanded, setExpanded] = React.useState(false);

  const { setThemeType } = React.useContext(ThemeContext);

  const currentThemeType = getCurrentThemeType();
  const isLightTheme = React.useMemo(() => currentThemeType === themeTypes.LIGHT_THEME, [currentThemeType]);

  const handleRedirectManagement = React.useCallback(() => {
    keycloak.accountManagement();
  }, [keycloak]);

  const handleChangeTheme = React.useCallback(() => {
    setThemeType(isLightTheme ? themeTypes.DARK_THEME : themeTypes.LIGHT_THEME);
  }, [isLightTheme, setThemeType]);

  const handleLogout = React.useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const handleClickManageUsers = React.useCallback(async () => {
    historyPush("/users");
  }, [historyPush]);

  const defaultItems = React.useMemo(() => {
    const items = [
      {
        icon: <Icon icon="home" />,
        label: "Accueil",
        title: "Accueil",
        onClick: () => historyPush("/"),
        group: NavigationGroup.name,
        order: 1,
      },
      {
        icon: <Icon icon="user-circle" />,
        label: "Mon profil",
        title: "Mon profil",
        onClick: handleRedirectManagement,
        order: 1,
      },
      {
        icon: <Icon icon="adjust" />,
        label: (
          <>
            Mode sombre/clair
            <Switch checked={isLightTheme} onChange={handleChangeTheme} color="default" name="isLightTheme" />
          </>
        ),
        title: "Mode sombre/clair",
        onClick: handleChangeTheme,
        order: 2,
      },
      {
        icon: <Icon icon="sign-out" />,
        label: "Déconnexion",
        title: "Déconnexion",
        onClick: handleLogout,
        order: 3,
      },
    ];

    if (isAdmin) {
      items.push({
        icon: <Icon icon="users" />,
        label: " Gestion des utilisateurs",
        title: "Gestion des utilisateurs",
        onClick: handleClickManageUsers,
        order: 1.5,
      });
    }
    return items;
  }, [
    handleChangeTheme,
    handleClickManageUsers,
    handleLogout,
    handleRedirectManagement,
    historyPush,
    isAdmin,
    isLightTheme,
  ]);

  return (
    <SideBarContext.Provider
      value={{
        groups: defaultGroups.concat(state.groups),
        items: defaultItems.concat(state.items),
        isExpanded,
        setExpanded,
        addGroups,
        removeGroups,
        addItems,
        removeItems,
      }}
    >
      <Modal open={!!modal.content} size={modal.size} title={modal.title} onClose={closeModal}>
        {modal.content}
      </Modal>
      {children}
    </SideBarContext.Provider>
  );
};

const sortFunction = (a, b) => (a.order || Infinity) - (b.order || Infinity);

const SideBar = () => {
  const { groups, items, isExpanded, setExpanded } = React.useContext(SideBarContext);

  const { pathname } = useLocation();

  const sortedGroups = React.useMemo(() => [...groups, undefined].sort(sortFunction), [groups]);
  const sortedItems = React.useMemo(() => [...items].sort(sortFunction), [items]);

  const getGroupItems = (g) => sortedItems.filter((item) => (!g && !item.group) || item.group === g?.name);

  const handleClickMenu = React.useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded, setExpanded]);

  const handleClickAway = React.useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  const groupItem = (item) => {
    const { icon, label, onClick, title } = item;
    return (
      <Box
        onClick={onClick}
        key={title}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          cursor: "pointer",
          height: "45px",
          width: isExpanded ? 300 : 50,
          "&:hover": {
            bgcolor: "secondary.main",
          },
        }}
        title={isExpanded ? title : null}
      >
        <Box sx={{ fontSize: "1.5rem", width: 50, textAlign: "center" }}>{icon}</Box>
        {isExpanded && <Box sx={{ ml: "10px", flexGrow: 1 }}>{label}</Box>}
      </Box>
    );
  };
  if (pathname === "/noLayout") {
    return null;
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ width: 50, position: "relative", zIndex: 500 }}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            width: isExpanded ? 300 : 50,
            overflow: "hidden",
            bgcolor: "layout.backgroundSidebar",
            borderRight: "1px solid",
            borderColor: "layout.border",
            transition: "width linear 350ms, background-color 1000ms ease, color 1000ms ease, border-color 1000ms ease",
            color: "layout.textSidebar",
            fontWeight: 600,
          }}
        >
          <Icon
            title="Menu"
            icon={isExpanded ? "times" : "bars"}
            sx={{ fontSize: "2rem", cursor: "pointer", px: 1.5, py: 3.25 }}
            onClick={handleClickMenu}
          />
          {sortedGroups.map((group, index) => {
            const groupItems = getGroupItems(group);
            if (!groupItems.length) {
              return null;
            }
            return (
              <React.Fragment key={group?.name || "default"}>
                {index > 0 && <Divider />}
                {groupItems.map((item) => {
                  return isExpanded ? (
                    groupItem(item)
                  ) : (
                    <Tooltip key={item?.title} title={item?.title} placement="right" arrow={true}>
                      {groupItem(item)}
                    </Tooltip>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SideBar;
