import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import contentsTypes from "cms/enums/contentsTypes";
import AdminListForm from "cms/back-office/components/contentForms/AdminListForm";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import ImageForm from "cms/back-office/components/contentForms/ImageForm";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";

const TabsForm = (props) => {
  const { content, onContentChange } = props;
  const { label, children, type } = content;
  const tabsTypes = [contentsTypes.TAB, contentsTypes.TAB_CARDS];

  const objectsChildren = children.filter((c) => tabsTypes.includes(c.type)) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren,
    });
  };

  return (
    <Fragment>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>

      <AdminListForm
        inline
        contents={objectsChildren}
        elementsType={type === contentsTypes.TABS ? contentsTypes.TAB : contentsTypes.TAB_CARDS}
        elementsKey="tabs"
        onContentsChange={(newContents) => handleChildrenChange(objectsChildren, newContents)}
      >
        {objectsChildren.map((currentObject) => (
          <div key={currentObject.id}>
            <InputForm
              content={{
                ...currentObject.children.find((c) => c.key === "title"),
              }}
              onContentChange={(newContent) =>
                handleChildChange(currentObject, {
                  ...currentObject,
                  children: (currentObject.children || []).map((c) => (c.key === "title" ? newContent : c)),
                })
              }
            />
            <ImageForm
              content={{
                ...currentObject.children.find((c) => c.key === "image"),
              }}
              onContentChange={(newContent) =>
                handleChildChange(currentObject, {
                  ...currentObject,
                  children: (currentObject.children || []).map((c) => (c.key === "image" ? newContent : c)),
                })
              }
            />
          </div>
        ))}
      </AdminListForm>
    </Fragment>
  );
};

TabsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default TabsForm;
