import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DuplicateIcon from "@mui/icons-material/CopyAll";
import RemoveIcon from "@mui/icons-material/Cancel";
import { AdminActionsContext, ActionPositions } from "cms/back-office/components/AdminActions";
import AdminContentModal, { canBeAdministrated } from "cms/back-office/components/AdminContentModal";
import SelectContentModal from "cms/back-office/components/SelectContentModal";
import { getAllDefaultContents, generateContentId } from "cms/back-office/utils/adminContentsUtils";

const AddContentButton = (props) => {
  const { index, addContentText, onClick } = props;

  const buttonPlusRef = React.useRef(null);

  const handleClick = React.useCallback(() => {
    onClick(index, buttonPlusRef?.current);
  }, [onClick, index]);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: "relative",
        cursor: "pointer",
        opacity: 0.1,
        transition: "opacity ease-in 100ms",
        py: 1.5,
        color: "#333366",
        ":hover": {
          opacity: 1,
          color: "#333366",
        },
      }}
      onClick={handleClick}
      title={addContentText}
    >
      <Grid
        item
        sx={{
          bgcolor: "currentcolor",
          pt: 0.25,
          pb: 0.375,
          px: 1.5,
          borderRadius: "12px",
          zIndex: 1,
        }}
      >
        <Box component="span" sx={{ color: "white" }} ref={buttonPlusRef}>
          +
        </Box>
      </Grid>
      <Box
        sx={{
          position: "absolute",
          top: "calc(50% - 1px)",
          left: "16px",
          right: "16px",
          borderTop: "3px solid",
        }}
      />
    </Grid>
  );
};

const DynamicAdmin = (props) => {
  const { children, content, onContentChange, addContentText, contentsTypes, limit, maxIndexForSpacing } = props;

  const { dynamicChildKey, id: contentId } = content;

  const [insertIndex, setInsertIndex] = useState();
  const [displayModalSelectContent, setDisplayModalSelectContent] = useState(false);
  const [modalSelectContentTarget, setModalSelectContentTarget] = useState(null);
  const [selectedContent, setSelectedContent] = useState();

  const availableContents = getAllDefaultContents().filter((content) => contentsTypes.includes(content.type));

  const handleAddChild = (newContent, index) => {
    const i = index !== undefined ? index : insertIndex;
    const { key, value = "", children: newContentChildren, type } = newContent;
    const newChild = {
      key: dynamicChildKey || key,
      value,
      type,
      children: newContentChildren,
    };

    const newChildren = [...(content.children || [])];
    const filterChildren = newChildren.filter((child) => child.key === newChild.key);
    let prevChild = null;
    if (i > 0) {
      prevChild = filterChildren[i - 1];
    }
    newChildren.splice(newChildren.indexOf(prevChild) + 1, 0, generateContentId(newChild));
    onContentChange({
      ...content,
      children: newChildren,
    });
    setSelectedContent();
  };

  const handleSelectContent = (content, index) => {
    setDisplayModalSelectContent(false);
    if (canBeAdministrated(content)) {
      if (index) {
        setInsertIndex(index);
      }
      setSelectedContent(content);
    } else {
      handleAddChild(content, index);
    }
  };

  const handleClickAddContent = (index, target) => {
    if (availableContents.length > 1) {
      setModalSelectContentTarget(target);
      setDisplayModalSelectContent(true);
      setInsertIndex(index);
    } else if (availableContents.length === 1) {
      handleSelectContent(availableContents[0], index);
    }
  };

  const handleRemoveChild = (index) => () => {
    const newChildren = [...(content.children || [])];
    const filterChildren = newChildren.filter((child) => child.key === dynamicChildKey);
    const childToRemove = filterChildren[index];
    onContentChange({
      ...content,
      children: newChildren.filter((child) => child !== childToRemove),
    });
  };

  const handleDuplicateChild = (index) => () => {
    const newChildren = [...(content.children || [])];
    const filterChildren = newChildren.filter((child) => child.key === dynamicChildKey);
    const childToDuplicate = filterChildren[index];
    const indexOfChildToDuplicate = newChildren.indexOf(childToDuplicate);
    const newChild = generateContentId({ ...childToDuplicate });
    newChildren.splice(indexOfChildToDuplicate, 0, newChild);
    onContentChange({
      ...content,
      children: newChildren,
    });
  };

  const appendButtonToNode = (node, index, allNodes = []) => {
    return (
      !!node && (
        <Fragment key={node.key}>
          {index === 0 && allNodes.length < limit && (
            <AddContentButton index={0} addContentText={addContentText} onClick={handleClickAddContent} />
          )}
          {maxIndexForSpacing && index > 0 && allNodes.length < limit && index <= maxIndexForSpacing && (
            <Box sx={{ height: 33, width: 1 }} />
          )}
          <Box sx={{ width: "100%" }}>
            <AdminActionsContext.Provider
              value={{
                parentActions: [
                  {
                    node: (
                      <DuplicateIcon
                        onClick={handleDuplicateChild(index)}
                        key={`DuplicateIcon-${contentId}`}
                        titleAccess={`Dupliquer cet élément`}
                      />
                    ),
                    position: ActionPositions.TOP_RIGHT,
                    order: 4,
                  },
                  {
                    node: (
                      <RemoveIcon
                        onClick={handleRemoveChild(index)}
                        key={`RemoveIcon-${contentId}`}
                        titleAccess={`Supprimer cet élément`}
                      />
                    ),
                    position: ActionPositions.TOP_RIGHT,
                    order: 5,
                  },
                ],
              }}
            >
              {node}
            </AdminActionsContext.Provider>
            {availableContents.length > 0 && allNodes.length < limit && (
              <AddContentButton index={index + 1} addContentText={addContentText} onClick={handleClickAddContent} />
            )}
          </Box>
        </Fragment>
      )
    );
  };

  const renderedChildren =
    typeof children === "function" ? children(appendButtonToNode) : React.Children.map(children, appendButtonToNode);
  let hasChildren = !!renderedChildren;
  if (Array.isArray(renderedChildren)) {
    hasChildren = renderedChildren.filter((c) => !!c).length > 0;
  }

  return (
    <Fragment>
      {!hasChildren && <AddContentButton index={0} addContentText={addContentText} onClick={handleClickAddContent} />}
      {renderedChildren}
      <SelectContentModal
        open={displayModalSelectContent}
        contents={availableContents}
        onSelectContent={handleSelectContent}
        onClose={() => setDisplayModalSelectContent(false)}
        target={modalSelectContentTarget}
      />
      <AdminContentModal
        open={!!selectedContent}
        content={selectedContent}
        onValidate={handleAddChild}
        onClose={() => setSelectedContent()}
      />
    </Fragment>
  );
};

DynamicAdmin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  addContentText: PropTypes.string,
  deleteContentText: PropTypes.string,
  contentsTypes: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxIndexForSpacing: PropTypes.number,
};

DynamicAdmin.defaultProps = {
  children: null,
  addContentText: "Ajouter un élement",
  deleteContentText: null,
  contentsTypes: [],
  limit: Infinity,
  maxIndexForSpacing: null,
};

export default DynamicAdmin;
