import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import contentsTypes from "cms/enums/contentsTypes";
import FilePicker from "cms/back-office/components/FilePicker";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";

const ImageForm = (props) => {
  const { content, onContentChange, formSubmitted } = props;
  const { id, label, children, requiredByKey, canChangePosition = false } = content;

  const { currentPageVersion } = useContext(AdminWebsiteContext);

  const fileImageChild = children.find((c) => c.type === contentsTypes.FILE_IMAGE);
  const altChild = children.find((c) => c.key === "alt");
  const titleChild = children.find((c) => c.key === "title");
  const positionChild = children.find((c) => c.key === "position");
  const imageFiltersChild = children.find((c) => c.key === "filters");

  const fileValue = {
    ...((fileImageChild && fileImageChild.value && JSON.parse(fileImageChild.value)) || {}),
    alt: altChild && altChild.value,
    title: titleChild && titleChild.value,
  };

  const handleSelectFile = (file) => {
    const { alt, title, position, filters } = file || {};

    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === fileImageChild) {
          return {
            ...child,
            value: file ? JSON.stringify(file) : "",
          };
        }
        if (child === altChild) {
          return {
            ...child,
            value: alt || "",
          };
        }
        if (child === titleChild) {
          return {
            ...child,
            value: title || "",
          };
        }
        if (filters && child === imageFiltersChild) {
          return filters;
        }

        if (canChangePosition && child === positionChild) {
          return {
            ...child,
            value: position || "",
          };
        }
        return child;
      }),
    });
  };

  return (
    <Box component="fieldset" sx={formStyled.fieldset} key={id}>
      <legend>{label}</legend>
      <FilePicker
        onSelectFile={handleSelectFile}
        pageVersion={currentPageVersion}
        image
        file={fileValue}
        required={!!requiredByKey}
        formSubmitted={formSubmitted}
        canChangePosition={canChangePosition}
        positionChild={positionChild}
        imageFiltersChild={imageFiltersChild}
      />
    </Box>
  );
};

ImageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
};

ImageForm.defaultProps = {
  formSubmitted: false,
};

export default ImageForm;
