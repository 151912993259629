import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export const AdminActionsContext = React.createContext({
  actions: [],
  parentActions: [],
});

export const ActionPositions = {
  TOP_RIGHT: "topRightActions",
  RIGHT: "rightActions",
  BOTTOM_RIGHT: "bottomRightActions",
  BOTTOM: "bottomActions",
  BOTTOM_LEFT: "bottomLeftActions",
  LEFT: "leftActions",
  TOP_LEFT: "topLeftActions",
  TOP: "topActions",
};

const actionsStyled = {
  position: "absolute",
  zIndex: "50",
  "&> *": {
    cursor: "pointer",
    pl: 0.25,
  },
};

const classes = {
  [ActionPositions.TOP_RIGHT]: {
    top: -12,
    right: -12,
  },
  [ActionPositions.RIGHT]: {
    top: 0,
    bottom: 0,
    right: -12,
    flexDirection: "column",
    justifyContent: "center",
  },
  [ActionPositions.BOTTOM_RIGHT]: {
    bottom: -12,
    right: -12,
  },
  [ActionPositions.BOTTOM]: {
    bottom: -12,
    right: 0,
    left: 0,
    justifyContent: "center",
  },
  [ActionPositions.BOTTOM_LEFT]: {
    bottom: -12,
    left: -12,
  },
  [ActionPositions.LEFT]: {
    top: 0,
    bottom: 0,
    left: -12,
    flexDirection: "column",
    justifyContent: "center",
  },
  [ActionPositions.TOP_LEFT]: {
    top: -12,
    left: -12,
  },
  [ActionPositions.TOP]: {
    top: -12,
    right: 0,
    left: 0,
    justifyContent: "center",
  },
};

const sortFunction = (a, b) => (a.order || Infinity) - (b.order || Infinity);

const defaultActionPositionKey = "default";

const AdminActions = (props) => {
  const { children, content, ...others } = props;

  const { actions } = React.useContext(AdminActionsContext);

  const { editionModalPosition, label } = content;
  const top = editionModalPosition === "top";

  const actionsByPosition = React.useMemo(() => {
    actions.sort(sortFunction);
    if (editionModalPosition) {
      return {
        [defaultActionPositionKey]: actions.map((a) => a.node),
      };
    }

    const value = {};
    actions.forEach((a) => {
      const { node, position = defaultActionPositionKey } = a;
      if (!value[position]) {
        value[position] = [];
      }
      value[position].push(node);
    });
    return value;
  }, [actions, editionModalPosition]);

  if (!actions?.length) {
    return children;
  }

  const { [defaultActionPositionKey]: defaultPositionActions, ...othersPositionsActions } = actionsByPosition;

  return (
    <Box
      sx={{
        position: "relative",
        borderWidth: "1px",
        borderStyle: "dashed",
        borderColor: "#33336618",
        mt: top ? 3 : 0,
        "&> * > .add, &> * > .delete, &> .actions": {
          opacity: 0,
        },
        "&:hover": {
          borderColor: "#333366",
          "&> * > .add, &> * > .delete, &> .actions": {
            opacity: 1,
          },
        },
      }}
      {...others}
    >
      <Stack
        direction="row"
        alignItems="center"
        className="actions"
        sx={{
          ...actionsStyled,
          top: top ? -39 : 12,
          left: top ? -1 : 12,
          border: top ? "1px solid rgba(0, 0, 0, 0.23)" : "",
          borderRadius: top ? "5px 5px 0px 0px" : "",
          p: top ? 1 : 0,
          bgcolor: top ? "rgba(255, 255, 255, 0.4)" : "",
        }}
      >
        {editionModalPosition ? <Box sx={{ pr: 1, cursor: "default" }}>{label}</Box> : null}
        {defaultPositionActions}
      </Stack>
      {Object.keys(othersPositionsActions).map((position) => (
        <Stack
          direction="row"
          alignItems="center"
          className="actions"
          sx={{
            ...actionsStyled,
            ...classes[position],
          }}
        >
          {othersPositionsActions[position]}
        </Stack>
      ))}
      {children}
    </Box>
  );
};

AdminActions.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.shape().isRequired,
};

export default AdminActions;
