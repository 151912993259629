import React, { useState, useEffect } from "react";
import { StaticRouter } from "react-router";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Modal, { ModalActions } from "cms/back-office/components/Modal";
import { enhanceContent } from "cms/back-office/utils/adminContentsUtils";
import ContentForm from "cms/back-office/components/contentForms/ContentForm";
import ThemeBackOfficeProvider from "cms/back-office/theme/ThemeBackOfficeProvider";
import { urlRegex } from "cms/utils/commonUtils";

export const canBeAdministrated = (content) => {
  const div = document.createElement("div");
  const root = createRoot(div);
  flushSync(() => {
    root.render(
      <ThemeBackOfficeProvider>
        <StaticRouter location={window.location}>
          <ContentForm content={content} topLevel />
        </StaticRouter>
      </ThemeBackOfficeProvider>,
    );
  });
  const hasContentForm = !!div.innerHTML;
  div.remove();
  return hasContentForm;
};

const AdminContentModal = (props) => {
  const { open, title, content, onValidate, onClose, ...others } = props;
  const [fullContent, setFullContent] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFullContent(enhanceContent(content));
    }
  }, [open, content]);

  useEffect(() => {
    if (open) {
      setFormSubmitted(false);
    }
  }, [open]);

  const checkChildHasFormValidation = (child) => {
    return child.regexValidation || child.requiredByKey;
  };

  const checkChildRegexValidation = (child) => {
    return !child.regexValidation || !child.value || child.value.match(child.regexValidation);
  };

  const checkFileUrlIsValid = (child) => {
    if (child.key === "file") {
      const valueParse = child.value ? JSON.parse(child.value) : "";
      const { url = "" } = valueParse;
      return url.match(urlRegex);
    }
    return true;
  };

  const checkChildFormValidation = (child, keys) => {
    if (!keys && !checkChildHasFormValidation(child) && (!child.children || child.children.length === 0)) return true;
    if (!checkChildRegexValidation(child) || !checkFileUrlIsValid(child)) return false;

    const hasRequiredKeys = child.requiredByKey || keys;
    if (hasRequiredKeys && (child.requiredByKey || keys).includes(child.key)) {
      return !!child.value;
    }

    if (child.children) {
      let childrenValidate = true;
      child.children.forEach((element) => {
        if (!checkChildFormValidation(element, child.requiredByKey || keys)) childrenValidate = false;
      });
      return childrenValidate;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    let formIsValid = true;
    if (fullContent.children) {
      if (!checkChildFormValidation(fullContent)) formIsValid = false;
    }

    if (formIsValid) onValidate(fullContent);
  };

  return (
    <ThemeBackOfficeProvider>
      <Modal
        aria-labelledby="admin-content"
        aria-describedby="content-administration"
        open={open}
        title={title}
        onClose={onClose}
        size="md"
        {...others}
      >
        <form onSubmit={handleSubmit}>
          <ContentForm topLevel content={fullContent} onContentChange={setFullContent} formSubmitted={formSubmitted} />
          <ModalActions>
            <Button color="secondary" onClick={onClose}>
              Annuler
            </Button>
            <Button type="submit" color="primary">
              {content && content.id ? "Mettre à jour" : "Créer"}
            </Button>
          </ModalActions>
        </form>
      </Modal>
    </ThemeBackOfficeProvider>
  );
};

AdminContentModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.shape(),
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AdminContentModal.defaultProps = {
  open: false,
  title: null,
  content: null,
};

export default AdminContentModal;
