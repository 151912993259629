import React, { useId } from "react";
import { useKeycloak } from "@react-keycloak/web";
import useTheme from "@mui/material/styles/useTheme";
import { styled } from "@mui/system";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Box, Grid, Link, Popover } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import AdminContentModal from "cms/back-office/components/AdminContentModal";
import AdminContext from "cms/back-office/components/AdminContext";
import AdminWebsiteContext, { AdminWebsiteContextProvider } from "cms/back-office/components/AdminWebsiteContext";
import AdminFooterForm from "cms/back-office/components/AdminFooterForm";
import AdminMenuForm from "cms/back-office/components/AdminMenuForm";
import AdminPageForm from "cms/back-office/components/AdminPageForm";
import AdminSection from "cms/back-office/components/AdminSection";
import AvailableContentsPreview from "cms/back-office/components/contentsPreview/AvailableContentsPreview";
import GEDModal from "cms/back-office/components/GEDModal";
import Modal from "cms/back-office/components/Modal";
import ModalConfirm from "cms/back-office/components/ModalConfirm";
import PagesTable from "cms/back-office/components/PagesTable";
import AdminPagesService from "cms/back-office/services/AdminPagesService";
import AdminPageVersionsService from "cms/back-office/services/AdminPageVersionsService";
import AdminSitesService from "cms/back-office/services/AdminSitesService";
import RenderPage from "cms/back-office/components/Layout/RenderPage";
import { SideBarContext } from "cms/back-office/components/Layout/SideBar";
import Icon from "cms/editableComponents/Icon";
import versionStatuses from "cms/enums/versionStatuses";
import AdminVersions from "cms/back-office/components/AdminVersions";
import AdminVersionsItemAction from "cms/back-office/components/AdminVersionsItemAction";
import { removeProperties } from "cms/utils/commonUtils";
import { adminProperties } from "cms/back-office/utils/adminContentsUtils";
import sectionsTypes from "cms/enums/sectionsTypes";
import { resetCache } from "cms/hooks/axiosCache";
import useEnvironment from "cms/back-office/hooks/useEnvironment";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";

const responsiveViews = [
  {
    key: "mobile",
    label: "Mobile",
    width: 414,
    height: 736,
    icon: "mobile",
  },
  {
    key: "tablet portrait",
    label: "Tablette (portrait)",
    width: 748,
    height: 1024,
    icon: "tablet",
  },
  {
    key: "tablet landscape",
    label: "Tablette (paysage)",
    width: 1024,
    height: 748,
    icon: "tablet",
    style: { transform: "rotate(-90deg)" },
  },
];

export const SiteGroup = {
  name: "site",
  order: 2,
};

export const PageGroup = {
  name: "page",
  order: 3,
};

export const MediaGroup = {
  name: "media",
  order: 4,
};

export const ContentsGroup = {
  name: "contents",
  order: 5,
};

const Warning = styled("span")({
  color: "#f44336",
  fontWeight: 600,
});

const isHomePage = (version) => {
  if (!version) {
    return false;
  }
  const { page } = version;
  return page && page.parentId === null && version.path === "/";
};

const defaultModal = {
  content: null,
  size: null,
  title: null,
};

const AdminWebsite = () => {
  const [pageVersion, setPageVersion] = React.useState();

  const [editionModeState, setEditionModeState] = React.useState(true);
  const [responsiveView, setResponsiveView] = React.useState(null);
  const [responsiveViewSelectorAnchorEl, setResponsiveViewSelectorAnchorEl] = React.useState(null);

  const [modal, setModal] = React.useState(defaultModal);
  const [adminContent, setAdminContent] = React.useState(null);

  const closeModal = React.useCallback(() => {
    setModal(defaultModal);
  }, []);

  const closeAdminContentModal = React.useCallback(() => {
    setAdminContent(null);
  }, []);

  const themeBackOffice = useTheme();

  const { displaySuccess, displayError } = React.useContext(AdminContext);

  const {
    allSites,
    currentSite,
    setCurrentSite,
    refreshSite,
    currentSiteTheme,
    currentPage,
    setCurrentPage,
    currentPageVersion,
    setCurrentPageVersion: setCurrentPageVersionContext,
    pageVersions,
    refreshPageVersions,
    goToHomePage,
    pageChanges,
    setPageChanges,
    versionHasChanged,
  } = React.useContext(AdminWebsiteContext);

  const excludedTemplatesForUpdate = React.useMemo(
    () => CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageUpdate) || [],
    [],
  );

  const excludedTemplatesForDelete = React.useMemo(
    () => CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageDelete) || [],
    [],
  );

  const lockedTemplate = React.useMemo(
    () => pageVersion && Boolean(excludedTemplatesForUpdate.find((template) => template.key === pageVersion?.template)),
    [excludedTemplatesForUpdate, pageVersion],
  );

  const lockedTemplateForDelete = React.useMemo(
    () => pageVersion && Boolean(excludedTemplatesForDelete.find((template) => template.key === pageVersion?.template)),
    [excludedTemplatesForDelete, pageVersion],
  );

  const { keycloak_client_ged, keycloak_client_cms } = useEnvironment();

  const { keycloak } = useKeycloak();

  const canUpdateSite = keycloak.hasResourceRole("update_site", keycloak_client_cms);
  const canCreatePage =
    keycloak.hasResourceRole("create_page", keycloak_client_cms) &&
    keycloak.hasResourceRole("create_page_version", keycloak_client_cms);
  const canPublishVersion =
    keycloak.hasResourceRole("update_page_version_status", keycloak_client_cms) && !lockedTemplate;
  const canUnpublish =
    React.useMemo(() => !isHomePage(pageVersion), [pageVersion]) &&
    keycloak.hasResourceRole("update_page_version_status", keycloak_client_cms) &&
    !lockedTemplate;
  const canUpdateVersion = keycloak.hasResourceRole("update_page_version", keycloak_client_cms) && !lockedTemplate;
  const canDeleteVersion = keycloak.hasResourceRole("delete_page_version", keycloak_client_cms) && !lockedTemplate;

  const canDeletePage = React.useMemo(
    () =>
      !pageVersions.find((version) => version.status === versionStatuses.PUBLISHED) &&
      keycloak.hasResourceRole("delete_page", keycloak_client_cms) &&
      !lockedTemplateForDelete,
    [keycloak, keycloak_client_cms, lockedTemplateForDelete, pageVersions],
  );

  const canAccessToGed = keycloak.hasResourceRole("ged_reader", keycloak_client_ged);

  const editionMode = editionModeState && canUpdateVersion;

  const {
    addItems: addSideBarItems,
    removeItems: removeSideBarItems,
    addGroups: addSideBarGroups,
    removeGroups: removeSideBarGroups,
    setExpanded: setSidebarExpanded,
  } = React.useContext(SideBarContext);

  React.useEffect(() => {
    const groups = [SiteGroup, PageGroup, MediaGroup, ContentsGroup];
    addSideBarGroups(groups);
    return () => {
      removeSideBarGroups(groups);
    };
  }, [addSideBarGroups, removeSideBarGroups]);

  React.useEffect(() => {
    setPageVersion(currentPageVersion);
  }, [currentPageVersion]);

  const handleUndo = React.useCallback(() => {
    const pageChange = { ...pageChanges[pageChanges.length - 2] };
    setPageVersion(pageChange);
    const changes = [...pageChanges];
    changes.splice(changes.length - 2, 2);
    setPageChanges(changes);
  }, [pageChanges, setPageChanges]);

  const pageRef = React.useRef(null);

  React.useEffect(() => {
    if (pageRef && pageRef.current) pageRef.current.scrollTop = 0;
  }, [currentPage]);

  const chosenDevice = React.useMemo(
    () => responsiveViews.find((res) => res.key === (responsiveView && responsiveView.key)),
    [responsiveView],
  );

  const setCurrentPageVersion = React.useCallback(
    (version = {}) =>
      refreshPageVersions().then((versions) => {
        setCurrentPageVersionContext(versions.find((v) => v.id === version.id));
      }),
    [refreshPageVersions, setCurrentPageVersionContext],
  );

  const handleSaveVersion = React.useCallback(
    (p) => {
      const promises = [];
      promises.push(AdminPagesService.update(p.page));
      if (p.status === versionStatuses.DRAFT) {
        promises.push(AdminPageVersionsService.update(p));

        return Promise.all(promises)
          .then((responses) => {
            const [, version] = responses;
            return version;
          })
          .catch(() => {
            setCurrentPageVersion(p);
            displayError("Erreur lors de la mise à jour du brouillon");
          });
      }
      promises.push(AdminPageVersionsService.create(p));
      return Promise.all(promises)
        .then((responses) => {
          const [, version] = responses;
          return version;
        })
        .catch(() => {
          setCurrentPageVersion(currentPageVersion);
          displayError("Erreur lors de la création du brouillon");
        });
    },
    [displayError, currentPageVersion, setCurrentPageVersion],
  );

  const handleClickResponsive = React.useCallback((event) => {
    setResponsiveViewSelectorAnchorEl(event.currentTarget);
  }, []);

  const handleClickView = React.useCallback(
    (view) => () => {
      setResponsiveView(view);
      setResponsiveViewSelectorAnchorEl(null);
    },
    [],
  );

  const updateSite = React.useCallback(
    (settings) => {
      const { children } = settings || {};
      const titleChild = children.find((c) => c.key === "title") || {};
      const newSettings = { ...settings, children: settings.children.filter((c) => c.key !== "title") };
      AdminSitesService.update({
        id: currentSite.id,
        title: titleChild.value,
        contents: currentSite.contents.map((c) =>
          c.key === "settings" ? removeProperties(newSettings, adminProperties) : c,
        ),
      })
        .then(() => {
          refreshSite();
          closeModal();
          displaySuccess("Le site a été mis à jour.");
        })
        .catch(() => {
          closeModal();
          displayError("Une erreur est survenue.");
        });
    },
    [closeModal, currentSite.contents, currentSite.id, displayError, displaySuccess, refreshSite],
  );

  const handleSiteSettingsChange = React.useCallback(
    (settings) => {
      setModal({
        content: (
          <ModalConfirm
            title={`Paramètres du site : ${currentSite.name}`}
            text="Attention, cette action sera effective immédiatement sur le site"
            confirmText="Mettre à jour le site"
            onConfirm={() => {
              updateSite(settings);
            }}
            onCancel={() => {
              closeModal();
              // eslint-disable-next-line no-use-before-define
              handleClickSiteSettings();
            }}
          />
        ),
        size: "sm",
      });
    },
    // eslint-disable-next-line no-use-before-define
    [closeModal, currentSite.name, handleClickSiteSettings, updateSite],
  );

  const handleValidateAdminContentModal = React.useCallback(
    (settings) => {
      closeAdminContentModal();
      handleSiteSettingsChange(settings);
    },
    [closeAdminContentModal, handleSiteSettingsChange],
  );

  const handleClickSiteSettings = React.useCallback(() => {
    const { title, contents = [] } = currentSite;
    const siteSettings = (contents && contents.find((c) => c.key === "settings")) || {};
    setAdminContent({
      ...siteSettings,
      children: [...(siteSettings.children || []), { key: "title", value: title }],
    });
  }, [currentSite]);

  const handleClickPages = React.useCallback(() => {
    setModal({
      content: (
        <Box sx={{ height: "65vh", overflow: "hidden" }}>
          <PagesTable
            onPageClick={(event, page) => {
              setCurrentPage(page);
              closeModal();
            }}
          />
        </Box>
      ),
      size: "md",
      title: "Les pages du site",
    });
  }, [closeModal, setCurrentPage]);

  const key = useId();
  const refreshPage = React.useCallback(() => {
    resetCache();
  }, []);

  const handleClickAdminMenu = React.useCallback(() => {
    setModal({
      content: (
        <AdminSection title="Gestion du menu" type={sectionsTypes.MENU} onSectionChanged={refreshPage}>
          {({ section, onSectionChange }) => (
            <AdminMenuForm menu={section} onMenuChange={onSectionChange} canUpdate={canUpdateVersion} />
          )}
        </AdminSection>
      ),
      size: "md",
    });
  }, [canUpdateVersion, refreshPage]);

  const handleClickAdminFooter = React.useCallback(() => {
    setModal({
      content: (
        <AdminSection title="Gestion du footer" type={sectionsTypes.FOOTER} onSectionChanged={refreshPage}>
          {({ section, onSectionChange }) => (
            <AdminFooterForm footer={section} onFooterChange={onSectionChange} canUpdate={canUpdateVersion} />
          )}
        </AdminSection>
      ),
      size: "lg",
    });
  }, [canUpdateVersion, refreshPage]);

  const createPage = React.useCallback(
    async (version) => {
      const { page } = version;
      const { name, parentId } = page;

      const newPage = {
        name,
        parentId,
        siteId: currentSite.id,
      };

      let createdPage = null;
      try {
        createdPage = await AdminPagesService.create(newPage);

        const newPageVersion = {
          ...version,
          page: createdPage,
        };

        try {
          await AdminPageVersionsService.create(newPageVersion);

          closeModal();
        } catch (e) {
          // Rollback page
          AdminPagesService.delete(createdPage);
          throw e;
        }
      } catch (e) {
        createdPage = null;
        console.error(e);
      }
      if (createdPage) {
        setCurrentPage(createdPage);
      }
    },
    [closeModal, currentSite.id, setCurrentPage],
  );

  const handleClickAddPage = React.useCallback(() => {
    setModal({
      content: <AdminPageForm onValidate={createPage} onCancel={closeModal} />,
      size: "md",
      title: "Créer une nouvelle page",
    });
  }, [closeModal, createPage]);

  const handleClickAdminPage = React.useCallback(() => {
    setModal({
      content: (
        <AdminPageForm
          version={pageVersion}
          onValidate={(p) => {
            handleSaveVersion(p).then(setCurrentPageVersion);
            closeModal();
          }}
          onCancel={closeModal}
        />
      ),
      size: "md",
      title: "Mettre à jour la page",
    });
  }, [pageVersion, closeModal, handleSaveVersion, setCurrentPageVersion]);

  const handleClickMedias = React.useCallback(() => {
    setModal({
      content: <GEDModal onCancel={closeModal} />,
      size: "lg",
    });
  }, [closeModal]);

  const handleClickPreviewContents = React.useCallback(() => {
    setModal({
      content: (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={currentSiteTheme}>
            <AvailableContentsPreview />
          </ThemeProvider>
        </StyledEngineProvider>
      ),
      size: "md",
    });
  }, [currentSiteTheme]);

  const [selectSiteAnchor, setSelectSiteAnchor] = React.useState(null);

  const handleClickChangeSite = React.useCallback((e) => {
    setSelectSiteAnchor(e.currentTarget);
  }, []);

  const handleClickSelectSite = React.useCallback(
    (site) => (e) => {
      e.stopPropagation();
      setCurrentSite(site);
      setSidebarExpanded(false);
      setSelectSiteAnchor(null);
    },
    [setCurrentSite, setSidebarExpanded],
  );

  React.useEffect(() => {
    const sideBarItems = [
      {
        icon: <Icon icon="compass" />,
        label: "Pages du site",
        title: "Pages du site",
        onClick: handleClickPages,
        group: SiteGroup.name,
        order: 3,
      },
      {
        icon: <Icon icon="bars" />,
        label: "Gérer le menu du site",
        title: "Gérer le menu du site",
        onClick: handleClickAdminMenu,
        group: SiteGroup.name,
        order: 4,
      },
      {
        icon: <Icon icon="line-columns" />,
        label: "Gérer le footer du site",
        title: "Gérer le footer du site",
        onClick: handleClickAdminFooter,
        group: SiteGroup.name,
        order: 5,
      },
      {
        icon: <Icon icon="layer-group" />,
        label: "Visualiser les composants",
        title: "Visualiser les composants",
        onClick: handleClickPreviewContents,
        group: ContentsGroup.name,
        order: 1,
      },
    ];

    if (canUpdateSite) {
      sideBarItems.push({
        icon: <Icon icon="cog" />,
        label: "Paramètres du site",
        title: "Paramètres du site",
        onClick: handleClickSiteSettings,
        group: SiteGroup.name,
        order: 2,
      });
    }
    if (canCreatePage) {
      sideBarItems.push({
        icon: <Icon icon="file-plus" />,
        label: "Créer une nouvelle page",
        title: "Créer une nouvelle page",
        onClick: handleClickAddPage,
        group: PageGroup.name,
        order: 1,
      });
    }

    if (canUpdateVersion) {
      sideBarItems.push({
        icon: <Icon icon="file-alt" />,
        label: "Paramètres de la page",
        title: "Paramètres de la page",
        onClick: handleClickAdminPage,
        group: PageGroup.name,
        order: 2,
      });
    }

    if (canAccessToGed) {
      sideBarItems.push({
        icon: <Icon icon="photo-video" />,
        label: "Médiathèque",
        title: "Médiathèque",
        onClick: handleClickMedias,
        group: MediaGroup.name,
        order: 1,
      });
    }

    if (Array.isArray(allSites) && allSites.length > 1) {
      sideBarItems.push({
        icon: (
          <>
            <Icon icon="globe-americas" />
          </>
        ),
        label: currentSite.name,
        title: `Changer de site (${currentSite.name})`,
        onClick: handleClickChangeSite,
        group: SiteGroup.name,
        order: 1,
      });
    }

    addSideBarItems(sideBarItems);
    return () => {
      removeSideBarItems(sideBarItems);
    };
  }, [
    addSideBarItems,
    allSites,
    canAccessToGed,
    canCreatePage,
    canUpdateSite,
    canUpdateVersion,
    currentSite,
    handleClickAddPage,
    handleClickAdminFooter,
    handleClickAdminMenu,
    handleClickAdminPage,
    handleClickChangeSite,
    handleClickMedias,
    handleClickPages,
    handleClickPreviewContents,
    handleClickSelectSite,
    handleClickSiteSettings,
    removeSideBarItems,
    selectSiteAnchor,
  ]);

  const handleDeletePageVersion = (version) => AdminPageVersionsService.delete(version);

  const handleDeletePage = React.useCallback(() => {
    setModal({
      content: (
        <ModalConfirm
          title={`Supprimer "${currentPageVersion?.page.name}"`}
          text={
            <Box pb={2}>
              Attention cette action est irréversible et entraîne :
              <br />
              <ul>
                <li>
                  La suppression de <Warning>cet article ainsi que de toutes ses versions</Warning>
                </li>
                <li>
                  La suppression de <Warning>tous les sous-articles</Warning> liés à cet article
                </li>
              </ul>
            </Box>
          }
          confirmText="Supprimer"
          confirmButtonProps={{ style: { backgroundColor: themeBackOffice.palette.error.main } }}
          onConfirm={() => {
            AdminPagesService.delete(currentPageVersion?.page).then(() => {
              goToHomePage();
            });
            closeModal();
          }}
          onCancel={closeModal}
        />
      ),
      size: "sm",
    });
  }, [closeModal, currentPageVersion, goToHomePage, themeBackOffice.palette.error.main]);

  const updateVersionStatus = React.useCallback(
    ({ version, status }) => {
      return AdminPageVersionsService.updateStatus(version, status).catch(() => {
        displayError("Erreur lors de la mise à jour du statut de la version");
      });
    },
    [displayError],
  );

  const updateTemplateSettings = React.useCallback(
    (newTemplateSettings) => {
      AdminSitesService.update({
        id: currentSite.id,
        title: currentSite.title,
        contents: currentSite.contents.map((c) =>
          c.key === "templatesSettings" ? removeProperties(newTemplateSettings, adminProperties) : c,
        ),
      })
        .then(() => {
          refreshSite();
          displaySuccess("Le gabarit a été mis à jour.");
        })
        .catch(() => {
          displayError("Une erreur est survenue.");
        });
    },
    [currentSite.contents, currentSite.id, currentSite.title, displayError, displaySuccess, refreshSite],
  );

  if (!pageVersion) {
    return null;
  }

  return (
    <>
      <Modal open={!!modal.content} size={modal.size} title={modal.title} onClose={closeModal}>
        {modal.content}
      </Modal>
      <Popover
        open={Boolean(selectSiteAnchor)}
        anchorEl={selectSiteAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={(e) => {
          e.stopPropagation();
          setSelectSiteAnchor(null);
        }}
      >
        {allSites.map((site) => (
          <Box
            key={site.id}
            onClick={handleClickSelectSite(site)}
            sx={{
              p: "8px",
              cursor: "pointer",
              bgcolor: currentSite.id === site.id ? "secondary.main" : "",
              color: currentSite.id === site.id ? "primary.light" : "",
              "&:hover": {
                bgcolor: "secondary.main",
              },
            }}
          >
            {site.name}
          </Box>
        ))}
      </Popover>
      <AdminContentModal
        open={!!adminContent}
        content={adminContent}
        onValidate={handleValidateAdminContentModal}
        onClose={closeAdminContentModal}
        disableEnforceFocus
        title={`Paramétres du site : ${currentSite.name}`}
      />
      <AdminVersions
        principal
        showVersion={!responsiveView}
        canDelete={canDeleteVersion}
        canPublish={canPublishVersion}
        canRename={canUpdateVersion}
        manualSave={canUpdateVersion}
        leftActions={
          <Grid container alignItems="center" sx={{ height: "100%" }}>
            {canUpdateVersion && (
              <AdminVersionsItemAction
                onClick={() => {
                  setEditionModeState(!editionMode);
                  setResponsiveView(null);
                }}
                title={editionMode ? "Mode prévisualisation" : "Mode édition"}
              >
                <Icon
                  title={editionMode ? "Mode prévisualisation" : "Mode édition"}
                  icon={editionMode ? "eye" : "edit"}
                  type="far"
                  color={themeBackOffice?.palette?.layout?.icon}
                  sx={{
                    mr: 2,
                  }}
                />
                <span>{editionMode ? "Mode prévisualisation" : "Mode édition"}</span>
              </AdminVersionsItemAction>
            )}
            {!editionMode && (
              <>
                <AdminVersionsItemAction title="Ordinateur" onClick={handleClickView(null)} selected={!responsiveView}>
                  <Icon icon="laptop" type="fal" />
                </AdminVersionsItemAction>
                <AdminVersionsItemAction title="Responsive" onClick={handleClickResponsive} selected={!!responsiveView}>
                  {chosenDevice?.Icon || (
                    <Icon
                      icon="phone-laptop"
                      type="fal"
                      color={themeBackOffice && themeBackOffice.palette.layout.icon}
                    />
                  )}
                </AdminVersionsItemAction>
                <Popover
                  open={!!responsiveViewSelectorAnchorEl}
                  anchorEl={responsiveViewSelectorAnchorEl}
                  onClose={() => setResponsiveViewSelectorAnchorEl(null)}
                  PaperProps={{
                    style: {
                      height: "56px",
                      maxHeight: "none",
                      borderRadius: 0,
                      backgroundColor: themeBackOffice && themeBackOffice.palette.layout.background,
                      borderTop: `1px solid ${themeBackOffice && themeBackOffice.palette.layout.border}`,
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Grid container sx={{ height: "100%" }}>
                    {responsiveViews.map((view) => (
                      <AdminVersionsItemAction
                        title={view.label}
                        onClick={handleClickView(view)}
                        selected={view.key === (chosenDevice && chosenDevice.key)}
                      >
                        <Icon
                          icon={view.icon}
                          type="fal"
                          color={themeBackOffice && themeBackOffice.palette.layout.icon}
                          sx={view.style}
                        />
                      </AdminVersionsItemAction>
                    ))}
                  </Grid>
                </Popover>
              </>
            )}
            {editionMode && (
              <AdminVersionsItemAction onClick={handleClickAdminPage} title="Paramètres de la page">
                <Icon
                  icon="file-alt"
                  title="Paramètres de la page"
                  color={themeBackOffice && themeBackOffice.palette.layout.icon}
                />
              </AdminVersionsItemAction>
            )}
            {!responsiveView && pageChanges.length > 1 && (
              <AdminVersionsItemAction onClick={handleUndo} title="Annuler la dernière action">
                <Icon
                  title="Annuler la dernière action"
                  icon="undo"
                  color={themeBackOffice && themeBackOffice.palette.layout.icon}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.35rem",
                  }}
                />
              </AdminVersionsItemAction>
            )}
          </Grid>
        }
        rightActions={
          canDeletePage && (
            <Link href="#" color="error" onClick={handleDeletePage}>
              Supprimer cette page
            </Link>
          )
        }
        versions={pageVersions}
        currentVersion={pageVersion}
        setCurrentVersion={setCurrentPageVersion}
        onVersionChange={setPageVersion}
        saveVersion={handleSaveVersion}
        canUnpublish={canUnpublish}
        updateStatus={updateVersionStatus}
        deleteVersion={handleDeletePageVersion}
        autoSaveDelay={process.env.autosave_delay || 0}
      >
        {({ handleVersionChange }) => (
          <Box sx={{ position: "relative", flex: 1, height: "100%" }}>
            <Box sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
              {editionMode || !responsiveView ? (
                <Box ref={pageRef} sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={currentSiteTheme}>
                      <RenderPage
                        key={key}
                        page={pageVersion}
                        handlePageChange={handleVersionChange}
                        handleTemplateSettingsChange={updateTemplateSettings}
                        editionMode={editionMode}
                      />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </Box>
              ) : (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  sx={{ height: !versionHasChanged ? "100%" : "auto" }}
                >
                  {versionHasChanged && (
                    <Grid
                      item
                      sx={{
                        bgcolor: "primary.main",
                        color: "primary.textDisabled",
                        p: 2,
                        borderRadius: "2px",
                        my: 3,
                        maxWidth: `${responsiveView.width}px`,
                      }}
                    >
                      <Grid container alignItems="center" wrap="nowrap">
                        <InfoOutlinedIcon sx={{ mr: 1 }} />
                        <Typography>
                          Vous avez des modifications en attente. <b>Enregistrez-les</b> pour les visualiser en vue
                          adaptative.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: !versionHasChanged ? "100%" : "auto" }}
                  >
                    <iframe
                      title="No layout"
                      src={`/noLayout${window.location.search}`}
                      style={{
                        resize: "both",
                        height: `${responsiveView.height}px`,
                        width: `${responsiveView.width}px`,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        )}
      </AdminVersions>
    </>
  );
};

export default (props) => (
  <AdminWebsiteContextProvider>
    <AdminWebsite {...props} />
  </AdminWebsiteContextProvider>
);
