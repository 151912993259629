import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconForm from "cms/back-office/components/contentForms/IconForm";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import SelectForm from "cms/back-office/components/contentForms/SelectForm";
import CheckboxForm from "cms/back-office/components/contentForms/CheckboxForm";
import AdminListForm from "cms/back-office/components/contentForms/AdminListForm";
import ImageForm from "cms/back-office/components/contentForms/ImageForm";
import TabPanel from "cms/back-office/components/TabPanel";
import IconLabel from "cms/back-office/components/IconLabel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import contentsTypes from "cms/enums/contentsTypes";
import { standardToolbarEditor } from "cms/utils/commonUtils";
import CKEditorCustom from "cms/back-office/components/CKEditorCustom";

const SiteSettingsForm = (props) => {
  const { content, onContentChange } = props;
  const { id, children = [] } = content;

  const titleChild = children.find((c) => c.key === "title") || {};
  const preTitleChild = children.find((c) => c.key === "preTitle") || {};
  const copyrightChild = children.find((c) => c.key === "copyright") || {};
  const warningBannerChild = children.find((c) => c.key === "warningBanner") || {};
  const socialNetworkListChild = children.filter((c) => c.key === "socialNetworks");
  const logoHeaderChild = children.find((c) => c.key === "logoHeader") || {};
  const logoFooterChild = children.find((c) => c.key === "logoFooter") || {};
  const newsletterImageChild = children.find((c) => c.key === "newsletterImage") || {};
  const newsletterTitleChild = children.find((c) => c.key === "newsletterTitle") || {};
  const newsletterSubTitleChild = children.find((c) => c.key === "newsletterSubTitle") || {};
  const newsletterInputLabelChild = children.find((c) => c.key === "newsletterInputLabel") || {};
  const newsletterCheckboxLabelChild = children.find((c) => c.key === "newsletterCheckboxLabel") || {};
  const newsletterCheckboxSubLabelChild = children.find((c) => c.key === "newsletterCheckboxSubLabel") || {};
  const newsletterButtonLabelChild = children.find((c) => c.key === "newsletterButtonLabel") || {};
  const newsletterDisplayModalDelayChild = children.find((c) => c.key === "newsletterDisplayModalDelay") || {};

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: [...children.filter((c) => !oldChildren.map((oldChild) => oldChild.id).includes(c.id)), ...newChildren],
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <div key={id}>
      <Box>
        <Tabs variant="scrollable" allowScrollButtonsMobile value={value} onChange={handleChange} sx={{ mt: 1, mb: 4 }}>
          <Tab label={<IconLabel icon="info-circle" title="Informations" />} {...a11yProps(0)} />
          <Tab label={<IconLabel icon="image" title="Logos" />} {...a11yProps(1)} />
          <Tab label={<IconLabel icon="file-invoice" title="Bandeau d'information" />} {...a11yProps(2)} />
          <Tab label={<IconLabel icon="chart-network" title="Réseaux sociaux" />} {...a11yProps(3)} />
          <Tab label={<IconLabel icon="mailbox" title="Newsletter" />} {...a11yProps(4)} />
        </Tabs>
        <Box sx={{ height: "50vh", mb: 3, overflowY: "auto", pr: 2 }}>
          <TabPanel value={value} index={0}>
            <InputForm
              content={{ ...titleChild, label: "Titre" }}
              onContentChange={(newContent) => handleChildChange(titleChild, newContent)}
            />
            <InputForm
              content={{ ...preTitleChild, label: "Pré titre" }}
              onContentChange={(newContent) => handleChildChange(preTitleChild, newContent)}
            />
            <InputForm
              content={{ ...copyrightChild, label: "Copyright" }}
              onContentChange={(newContent) => handleChildChange(copyrightChild, newContent)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ImageForm
              content={{ ...logoHeaderChild, label: "Logo en haut de page" }}
              onContentChange={(newContent) => handleChildChange(logoHeaderChild, newContent)}
            />
            <ImageForm
              content={{ ...logoFooterChild, label: "Logo en bas de page" }}
              onContentChange={(newContent) => handleChildChange(logoFooterChild, newContent)}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <CheckboxForm
              content={{
                ...(warningBannerChild.children || []).find((c) => c.key === "enable"),
                label: "Activer le bandeau d'information",
              }}
              onContentChange={(newContent) =>
                handleChildChange(warningBannerChild, {
                  ...warningBannerChild,
                  children: (warningBannerChild.children || []).map((c) => (c.key === "enable" ? newContent : c)),
                })
              }
            />
            <Fragment>
              <CKEditorCustom
                key={((warningBannerChild.children || []).find((c) => c.key === "message") || {}).value.id}
                onChange={(data) =>
                  handleChildChange(warningBannerChild, {
                    ...warningBannerChild,
                    children: (warningBannerChild.children || []).map((c) =>
                      c.key === "message" ? { ...c, value: encodeURIComponent(data) } : c,
                    ),
                  })
                }
                initData={decodeURIComponent(
                  ((warningBannerChild.children || []).find((c) => c.key === "message") || {}).value,
                )}
                config={{ toolbar: standardToolbarEditor }}
                debounce={0}
              />
              <SelectForm
                content={(warningBannerChild.children || []).find((c) => c.key === "severity")}
                onContentChange={(newContent) =>
                  handleChildChange(warningBannerChild, {
                    ...warningBannerChild,
                    children: (warningBannerChild.children || []).map((c) => (c.key === "severity" ? newContent : c)),
                  })
                }
              />
            </Fragment>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AdminListForm
              inline
              contents={socialNetworkListChild}
              elementsType={contentsTypes.SOCIAL_NETWORK}
              elementsKey="socialNetworks"
              onContentsChange={(newContents) => handleChildrenChange(socialNetworkListChild, newContents)}
            >
              {socialNetworkListChild.map((socialNetwork) => (
                <Box
                  key={socialNetwork.id}
                  sx={{
                    mb: 1.75,
                    "& > legend": {
                      color: "secondary.text",
                    },
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <InputForm
                        content={socialNetwork.children.find((c) => c.key === "url")}
                        onContentChange={(newContent) =>
                          handleChildChange(socialNetwork, {
                            ...socialNetwork,
                            children: (socialNetwork.children || []).map((c) => (c.key === "url" ? newContent : c)),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputForm
                        content={socialNetwork.children.find((c) => c.key === "title")}
                        onContentChange={(newContent) =>
                          handleChildChange(socialNetwork, {
                            ...socialNetwork,
                            children: (socialNetwork.children || []).map((c) => (c.key === "title" ? newContent : c)),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <IconForm
                    content={socialNetwork.children.find((c) => c.key === "icon")}
                    onContentChange={(newContent) =>
                      handleChildChange(socialNetwork, {
                        ...socialNetwork,
                        children: (socialNetwork.children || []).map((c) => (c.key === "icon" ? newContent : c)),
                      })
                    }
                  />
                </Box>
              ))}
            </AdminListForm>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <InputForm
              content={{ ...newsletterTitleChild, label: "Titre" }}
              onContentChange={(newContent) => handleChildChange(newsletterTitleChild, newContent)}
            />
            <InputForm
              content={{ ...newsletterSubTitleChild, label: "Sous-titre" }}
              onContentChange={(newContent) => handleChildChange(newsletterSubTitleChild, newContent)}
            />
            <ImageForm
              content={{ ...newsletterImageChild, label: "Image" }}
              onContentChange={(newContent) => handleChildChange(newsletterImageChild, newContent)}
            />
            <InputForm
              content={{ ...newsletterInputLabelChild, label: "Titre du champ de saisie" }}
              onContentChange={(newContent) => handleChildChange(newsletterInputLabelChild, newContent)}
            />
            <InputLabel
              shrink
              sx={{
                pointerEvents: "auto",
              }}
            >
              Texte de consentement
            </InputLabel>
            <CKEditorCustom
              key={newsletterCheckboxLabelChild.id}
              onChange={(newContent) => {
                handleChildChange(newsletterCheckboxLabelChild, {
                  ...newsletterCheckboxLabelChild,
                  value: encodeURIComponent(newContent),
                });
              }}
              initData={decodeURIComponent(newsletterCheckboxLabelChild.value)}
              config={{ toolbar: standardToolbarEditor }}
              debounce={0}
            />
            <InputForm
              content={{ ...newsletterCheckboxSubLabelChild, label: "Texte de désabonnement" }}
              onContentChange={(newContent) => handleChildChange(newsletterCheckboxSubLabelChild, newContent)}
            />
            <InputForm
              content={{ ...newsletterButtonLabelChild, label: "Texte de bouton" }}
              onContentChange={(newContent) => handleChildChange(newsletterButtonLabelChild, newContent)}
            />
            <InputForm
              content={{ ...newsletterDisplayModalDelayChild, label: "Délai d'apparition de la modale (en secondes)" }}
              onContentChange={(newContent) => handleChildChange(newsletterDisplayModalDelayChild, newContent)}
              type="number"
            />
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
};

SiteSettingsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SiteSettingsForm;
