import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/system/Box";
import { formControlStyled } from "cms/back-office/components/contentForms/ContentForm";

const CheckboxForm = (props) => {
  const { content, onContentChange } = props;

  const { value, width } = content;

  const handleCheckboxChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.checked ? "true" : "false",
    });
  };

  return (
    <Box sx={{ display: "inline-flex", ...formControlStyled(width) }}>
      <FormControlLabel
        control={<Checkbox checked={value === "true"} onChange={handleCheckboxChange} disableRipple />}
        label={content.label}
      />
    </Box>
  );
};

CheckboxForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default CheckboxForm;
