import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useQueryClient } from "react-query";
import AdminContext from "cms/back-office/components/AdminContext";
import AreaForm from "bo/AreaForm";
import { useAreaCreate, entityName } from "services/area.service";
var CreateArea = function (props) {
    var onValidate = props.onValidate, onCancel = props.onCancel;
    var _a = React.useContext(AdminContext), displaySuccess = _a.displaySuccess, displayError = _a.displayError;
    var queryClient = useQueryClient();
    var createArea = useAreaCreate({
        onSuccess: function () {
            queryClient.invalidateQueries(entityName);
            displaySuccess("Nouvel établissement créé");
            onValidate();
        },
        onError: function () {
            displayError("Erreur lors de la création du territoire");
        },
    }).mutate;
    var handleValidateArea = function (values) {
        createArea(values);
    };
    return _jsx(AreaForm, { onSubmit: handleValidateArea, onCancel: onCancel });
};
export default CreateArea;
