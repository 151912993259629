import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingPage = (props) => {
  const { title } = props;

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "1.3rem",
        backgroundColor: "#fff",
        "& .MuiCircularProgressCircle": {
          stroke: "#3787FF",
          strokeLinecap: "round",
        },
        "& > span": {
          color: "#666666",
          mt: 2,
        },
      }}
    >
      <CircularProgress />
      <span>{title}</span>
    </Box>
  );
};

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoadingPage;
