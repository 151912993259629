import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { getDefaultContentByType } from "cms/back-office/utils/adminContentsUtils";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";
import contentsTypes from "cms/enums/contentsTypes";
import CheckboxForm from "./CheckboxForm";
import SliderForm from "./SliderForm";

const defaultFilters = getDefaultContentByType(contentsTypes.IMAGE_FILTERS);

const ImageFiltersForm = (props) => {
  const { content, onContentChange } = props;

  const { id, label, children } = content;

  const activatedChild = React.useMemo(() => children.find((c) => c.key === "activated"), [children]);

  const filtersChildren = React.useMemo(() => children.filter((c) => c !== activatedChild), [children, activatedChild]);

  const handleChangeChild = (child) => (newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => {
        if (c === child) {
          return newChild;
        }
        return c;
      }),
    });
  };

  const handleClickResetFilters = React.useCallback(
    (e) => {
      e.preventDefault();
      onContentChange({
        ...content,
        children: children.map((c) => {
          if (c === activatedChild) {
            return c;
          }
          return {
            ...c,
            value: defaultFilters?.children?.find((defaultChild) => defaultChild.key === c.key)?.value,
          };
        }),
      });
    },
    [children, activatedChild, content, onContentChange],
  );

  return (
    <Box component="fieldset" sx={formStyled.fieldset} key={id}>
      <legend>{label}</legend>
      <CheckboxForm content={activatedChild} onContentChange={handleChangeChild(activatedChild)} />
      {activatedChild?.value === "true" && (
        <>
          <br />
          <Link sx={{ fontSize: 12, cursor: "pointer", mb: 1, display: "block" }} onClick={handleClickResetFilters}>
            Valeurs par défaut
          </Link>
          <Grid container spacing={1}>
            {filtersChildren.map((filterChild) => (
              <Grid item xs={6} key={filterChild.id}>
                <SliderForm content={filterChild} onContentChange={handleChangeChild(filterChild)} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

ImageFiltersForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default ImageFiltersForm;
