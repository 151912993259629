import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import FormHelperText from "@mui/material/FormHelperText";
import { formControlStyled, formStyled } from "cms/back-office/components/contentForms/ContentForm";

const InputForm = (props) => {
  const { content, onContentChange, formSubmitted, required, regexValidation, tooltip, ...others } = props;

  const { id, label, value, width } = content;

  const handleInputChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  const showRequiredError = required && formSubmitted && !value;
  const showRegexValidationError = regexValidation && formSubmitted && value && !value.match(regexValidation);

  return (
    <FormControl sx={formControlStyled(width)} key={id} error={showRequiredError || showRegexValidationError}>
      <InputLabel
        shrink
        sx={{
          pointerEvents: "auto",
          ...(tooltip ? formStyled.labelWithTooltip : {}),
        }}
      >
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpIcon />
          </Tooltip>
        )}
      </InputLabel>
      <Input value={value} onChange={handleInputChange} {...others} />
      {showRequiredError && <FormHelperText error>Champ obligatoire</FormHelperText>}
      {showRegexValidationError && <FormHelperText error>{label} invalide</FormHelperText>}
    </FormControl>
  );
};

InputForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool,
  regexValidation: PropTypes.instanceOf(RegExp),
  tooltip: PropTypes.string,
};

InputForm.defaultProps = {
  formSubmitted: false,
  required: false,
  regexValidation: null,
  tooltip: null,
};

export default InputForm;
