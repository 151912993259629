import React, { useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { CKEditor } from "ckeditor4-react";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";

function reducer(state, action) {
  return action.value;
}

const CKEditorCustom = (props) => {
  const { onChange, initData, debounce, config, ...others } = props;

  const timeoutRef = useRef(null);
  const { setEditorValueChange } = useContext(AdminWebsiteContext);
  const [localData, dispatch] = React.useReducer(reducer, initData);

  useEffect(() => {
    dispatch({ value: initData });
  }, [initData]);

  useEffect(() => {
    if (localData !== initData) {
      if (timeoutRef && timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        setEditorValueChange(true);
        onChange(localData);
      }, debounce);
    }
  }, [debounce, initData, localData, onChange, setEditorValueChange]);

  return (
    <CKEditor
      onBeforeLoad={(CKEDITOR) => {
        // eslint-disable-next-line no-param-reassign
        CKEDITOR.disableAutoInline = true;
      }}
      {...others}
      editorUrl="/ckeditor/ckeditor.js"
      initData={localData}
      config={{ ...config, baseFloatZIndex: 1350 }}
      onChange={(e) => dispatch({ value: e.editor.getData() })}
    />
  );
};

CKEditorCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.string,
  debounce: PropTypes.number,
  config: PropTypes.object,
};

CKEditorCustom.defaultProps = {
  data: "",
  debounce: 2000,
  config: {},
};

export default CKEditorCustom;
