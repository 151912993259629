import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import contentsTypes from "cms/enums/contentsTypes";
import AdminListForm from "cms/back-office/components/contentForms/AdminListForm";
import AdminContentModal, { canBeAdministrated } from "cms/back-office/components/AdminContentModal";
import SelectContentModal from "cms/back-office/components/SelectContentModal";
import Icon from "cms/editableComponents/Icon";
import { generateContentId, getDefaultContentByType, enhanceContent } from "cms/back-office/utils/adminContentsUtils";

const defaultMenuItemModal = {
  open: false,
  menuItem: null,
  onValidate: () => {},
};

const defaultSelectContentModal = {
  open: false,
  depth: 0,
  onValidate: () => {},
};

const AdminMenuItems = (props) => {
  const { items, itemKey, subItemKey, onItemsChange, maxDepth, canUpdate } = props;

  const [modalSelectContent, setModalSelectContent] = useState(defaultSelectContentModal);
  const { open: selectContentModalOpen, depth: currentDepth, onValidate: onValidateAddContent } = modalSelectContent;

  const [menuItemModal, setMenuItemModal] = useState(defaultMenuItemModal);
  const { open: itemModalOpen, onValidate: onValidateItem, menuItem: currentMenuItem } = menuItemModal;

  const closeSelectContentModal = useCallback(() => setModalSelectContent(defaultSelectContentModal), []);
  const closeMenuItemModal = useCallback(() => setMenuItemModal(defaultMenuItemModal), []);

  const [groupOpen, setGroupOpen] = useState(null);

  const toggleGroupOpen = useCallback(
    (group) => () => {
      if (group === groupOpen) {
        setGroupOpen(null);
      } else {
        setGroupOpen(group);
      }
    },
    [groupOpen],
  );

  const handleItemChange = (item) => {
    const { id } = item;
    onItemsChange(items.map((c) => (c.id !== id ? c : item)));
  };

  const getAvailableContents = (depth = currentDepth) => {
    const availableContents = [getDefaultContentByType(contentsTypes.MENU_ITEM)];
    if (depth < maxDepth) {
      availableContents.push(getDefaultContentByType(contentsTypes.MENU_GROUP));
    }
    return availableContents;
  };

  const handleSelectContent = (content, onValidate = onValidateAddContent) => {
    if (canBeAdministrated(content)) {
      setMenuItemModal({
        open: true,
        menuItem: enhanceContent(content),
        onValidate: (c) => {
          onValidate(c);
          closeMenuItemModal();
        },
      });
    } else {
      onValidate(content);
    }
    closeSelectContentModal();
  };

  const handleClickAddContent = (onValidate, depth = 0) => {
    const availableContents = getAvailableContents(depth);
    if (availableContents.length > 1) {
      setModalSelectContent({
        open: true,
        depth,
        onValidate,
      });
    } else if (availableContents.length === 1) {
      handleSelectContent(availableContents[0], onValidate);
    }
  };

  const handleClickEditMenuItem = (menuItem, onValidate) => (e) => {
    e.stopPropagation();
    setMenuItemModal({
      open: true,
      menuItem: enhanceContent(menuItem),
      onValidate: (c) => {
        onValidate(c);
        closeMenuItemModal();
      },
    });
  };

  const renderMenuItem = (menuItem, onItemChange, depth) => {
    const { id, children, type } = menuItem;
    const titleChild = children.find((c) => c.key === "title");
    const subMenusChild = children.filter((c) => c.key === subItemKey);

    const isGroup = type === contentsTypes.MENU_GROUP;
    const isOpen = menuItem.id === (groupOpen && groupOpen.id);

    const handleItemChildrenChange = (newChildren) => {
      onItemChange({
        ...menuItem,
        children: newChildren,
      });
    };

    const handleSubMenuChange = (updatedSubMenu) => {
      onItemChange({
        ...menuItem,
        children: children.map((child) => (child.id === updatedSubMenu.id ? updatedSubMenu : child)),
      });
    };

    const addSubMenuItem = (subMenuItem) => {
      onItemChange({
        ...menuItem,
        children: [...children, generateContentId({ key: subItemKey, ...subMenuItem })],
      });
    };

    const subMenus = subMenusChild.map((item) => renderMenuItem(item, handleSubMenuChange, depth + 1));

    return (
      <div key={id}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            cursor: "pointer",
            height: "40px",
            px: 1,
            "&:hover": {
              bgcolor: "secondary.main",
            },
          }}
          onClick={isGroup ? toggleGroupOpen(menuItem) : handleClickEditMenuItem(menuItem, onItemChange)}
        >
          {canUpdate && <Icon icon="edit" type="fas" onClick={handleClickEditMenuItem(menuItem, onItemChange)} />}
          <Box component="span" px={1}>
            {titleChild && titleChild.value}
          </Box>
          {isGroup && <Icon icon="plus" title="Voir" />}
        </Stack>
        {isGroup && isOpen && (
          <Box ml={6} border={1} p={1}>
            {canUpdate ? (
              <AdminListForm inline contents={children} onContentsChange={handleItemChildrenChange}>
                {subMenus}
              </AdminListForm>
            ) : (
              subMenus
            )}
            {canUpdate && (
              <Box ml={4}>
                <MuiLink
                  component="button"
                  variant="body2"
                  onClick={() => handleClickAddContent(addSubMenuItem, depth + 1)}
                >
                  Ajouter un élément
                </MuiLink>
              </Box>
            )}
          </Box>
        )}
      </div>
    );
  };

  const addMenuItem = (menuItem) => {
    onItemsChange([...items, generateContentId({ key: itemKey, ...menuItem })]);
  };

  return (
    <div>
      {canUpdate ? (
        <AdminListForm inline contents={items} onContentsChange={onItemsChange}>
          {items.map((menuItem) => renderMenuItem(menuItem, handleItemChange, 0))}
        </AdminListForm>
      ) : (
        items.map((menuItem) => renderMenuItem(menuItem, handleItemChange, 0))
      )}
      {canUpdate && (
        <>
          <Box ml={4}>
            <MuiLink component="button" variant="body2" onClick={() => handleClickAddContent(addMenuItem, 0)}>
              Ajouter un élément
            </MuiLink>
          </Box>
          <SelectContentModal
            open={selectContentModalOpen}
            contents={getAvailableContents()}
            onSelectContent={handleSelectContent}
            onClose={closeSelectContentModal}
          />
          <AdminContentModal
            open={itemModalOpen}
            content={currentMenuItem}
            onValidate={onValidateItem}
            onClose={closeMenuItemModal}
          />
        </>
      )}
    </div>
  );
};

AdminMenuItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onItemsChange: PropTypes.func.isRequired,
  itemKey: PropTypes.string.isRequired,
  subItemKey: PropTypes.string,
  maxDepth: PropTypes.number,
  canUpdate: PropTypes.bool,
};

AdminMenuItems.defaultProps = {
  maxDepth: 0,
  subItemKey: "subMenus",
  canUpdate: true,
};

export default AdminMenuItems;
