import React from "react";
import NodesListing from "da-ged-web/components/NodesListing/NodesListing";
import FullToolbar from "da-ged-web/components/Toolbar/FullToolbar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const GEDModal = () => {
  return (
    <Stack sx={{ height: "85vh" }}>
      <div>
        <FullToolbar />
      </div>
      <Box sx={{ flex: "1" }}>
        <NodesListing
          tableProps={{
            rowHeight: 35,
          }}
        />
      </Box>
    </Stack>
  );
};

export default GEDModal;
