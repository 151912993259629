import { createTheme } from "@mui/material/styles";
export default createTheme({
    typography: {
        fontSize: 14,
        fontFamily: '"Aileron"',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                containedSecondary: {
                    backgroundColor: "#e0e0e0",
                    color: "rgba(0, 0, 0, 0.87)",
                    "&:hover": {
                        backgroundColor: "#d5d5d5",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                },
            },
        },
    },
});
