import React, { useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { SiteContextProvider } from "cms/components/SiteContext";
import AdminWebsiteContext from "cms/back-office/components/AdminWebsiteContext";
import AdminTemplateSettings from "cms/back-office/components/AdminTemplateSettings";
import AdminTemplateSettingsContext from "cms/back-office/components/AdminTemplateSettingsContext";
import generateEditablePage, {
  generateTemplatePropsFromContents as generateTemplatePropsFromAdminContents,
} from "cms/back-office/utils/templateAdminUtils";
import { enhancePageVersionContents, getTemplateComponentByKey } from "cms/utils/templateUtils";
import Page from "cms/front-office/components/Page";
import PageVersionContext from "cms/components/PageVersionContext";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import contentsTypes from "cms/enums/contentsTypes";
import Box from "@mui/material/Box";

const RenderPage = (props) => {
  const Layout = CmsHooks.getHook(HOOKS.App_Layout) || (({ children }) => children);

  const { page: pageProps, handlePageChange, handleTemplateSettingsChange, editionMode } = props;

  const page = enhancePageVersionContents(pageProps);

  const [displayAdminTemplateSettingsModal, setDisplayAdminTemplateSettingsModal] = useState(false);

  const { currentSite, currentPageVersion, setCurrentPageVersion } = useContext(AdminWebsiteContext);
  const { contents = [] } = currentSite;

  const getTemplatesSettings = useCallback(() => {
    return (contents && contents.find((c) => c.type === contentsTypes.TEMPLATES_SETTINGS)) || {};
  }, [contents]);

  const { template } = page || {};

  const getTemplateSettings = useCallback(() => {
    const templatesSettings = getTemplatesSettings();
    const currentTemplateSettings = templatesSettings.children
      ? templatesSettings.children.find((c) => c.key === template)
      : null;

    if (currentTemplateSettings && currentTemplateSettings.children) {
      const currentTemplateSettingsChildren = [];
      currentTemplateSettings.children.forEach((c) => {
        currentTemplateSettingsChildren.push({ ...c, isTemplateSettings: true });
      });
      return { ...currentTemplateSettings, children: currentTemplateSettingsChildren };
    }

    return currentTemplateSettings;
  }, [template, getTemplatesSettings]);

  const [templateIsModify, setTemplateIsModify] = useState(false);
  const [previousTemplateSettings, setPreviousTemplateSettings] = useState(getTemplateSettings());
  const [currentTemplateSettings, setCurrentTemplateSettings] = useState(getTemplateSettings());

  useEffect(() => {
    setPreviousTemplateSettings(getTemplateSettings());
    setCurrentTemplateSettings(getTemplateSettings());
  }, [getTemplateSettings]);

  const generateTemplateSettingsProps = () => {
    if (editionMode) return generateTemplatePropsFromAdminContents(currentTemplateSettings.children);
    return generateTemplatePropsFromContents(currentTemplateSettings.children);
  };
  const templateSettingsProps = currentTemplateSettings ? generateTemplateSettingsProps() : {};

  if (!page) {
    return null;
  }

  const TemplateTag = getTemplateComponentByKey(template);

  const handleChangeTemplateSettings = (newTemplateSettings) => {
    setTemplateIsModify(true);
    setCurrentTemplateSettings(newTemplateSettings);
  };

  const handleValidateTemplateSettingsChange = () => {
    const templatesSettings = getTemplatesSettings();

    handleTemplateSettingsChange({
      ...templatesSettings,
      children: templatesSettings.children.map((t) => (t.key === template ? currentTemplateSettings : t)),
    });
  };

  const handleCancelTemplateSettingsChange = () => {
    setCurrentTemplateSettings(previousTemplateSettings);
  };

  const getCurrentTemplate = () => {
    const templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
    return templates.find((t) => t.key === template);
  };

  return (
    <SiteContextProvider site={currentSite}>
      <PageVersionContext.Provider value={{ currentPageVersion, setCurrentPageVersion }}>
        <Layout bo>
          {/* <p>{`Bonjour ${keycloakUserInfo.given_name} ${keycloakUserInfo.family_name}`}</p> */}
          <Box
            sx={{
              "& .cke_editable, & input,  & textarea, & pre": {
                bgcolor: "transparent",
                fontSize: "inherit",
                fontWeight: "inherit",
                fontFamily: "inherit",
                lineHeight: "inherit",
                textAlign: "inherit",
                letterSpacing: "inherit",
                p: 0,
                width: "100%",
              },
              "& .cke_editable, & input,  & textarea": {
                border: "1px dashed #999",
              },
              "& pre": {
                whiteSpace: "pre-wrap",
              },
            }}
          >
            {editionMode ? (
              <AdminTemplateSettingsContext.Provider value={{ setDisplayAdminTemplateSettingsModal }}>
                <AdminTemplateSettings
                  templateSettings={currentTemplateSettings}
                  handleChangeTemplateSettings={handleChangeTemplateSettings}
                  displayModal={displayAdminTemplateSettingsModal}
                  setDisplayModal={setDisplayAdminTemplateSettingsModal}
                  handleValidateTemplateSettingsChange={handleValidateTemplateSettingsChange}
                  handleCancelTemplateSettingsChange={handleCancelTemplateSettingsChange}
                  templateIsModify={templateIsModify}
                  setTemplateIsModify={setTemplateIsModify}
                />
                <TemplateTag
                  page={generateEditablePage(page, handlePageChange, getCurrentTemplate())}
                  {...templateSettingsProps}
                />
              </AdminTemplateSettingsContext.Provider>
            ) : (
              <Page page={page} templatesSettings={templateSettingsProps} />
            )}
          </Box>
        </Layout>
      </PageVersionContext.Provider>
    </SiteContextProvider>
  );
};

RenderPage.propTypes = {
  page: PropTypes.shape(),
  handlePageChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool.isRequired,
  handleTemplateSettingsChange: PropTypes.func.isRequired,
};

RenderPage.defaultProps = {
  page: null,
};

export default RenderPage;
