import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextField from "cms/components/TextField";
import Button from "@mui/material/Button";
import { ModalActions } from "cms/back-office/components/Modal";

const RenameVersionForm = (props) => {
  const { versionName, onValidate, onCancel } = props;

  const [name, setName] = useState(versionName || "");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleValidate = () => {
    if (typeof onValidate === "function") {
      onValidate(name);
    }
  };

  return (
    <Fragment>
      <h2>Renommer cette version</h2>
      <Box mb={2}>
        <TextField label="Nom de la version" value={name} onChange={handleNameChange} />
      </Box>
      <ModalActions>
        <Button color="secondary" onClick={onCancel}>
          Annuler
        </Button>
        <Button color="primary" onClick={handleValidate}>
          Renommer
        </Button>
      </ModalActions>
    </Fragment>
  );
};

RenameVersionForm.propTypes = {
  versionName: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

RenameVersionForm.defaultProps = {
  versionName: "",
};

export default RenameVersionForm;
