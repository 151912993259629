import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import MenuItem from "@mui/material/MenuItem";
import Select from "cms/components/Select";
import { formControlStyled, formStyled } from "cms/back-office/components/contentForms/ContentForm";

const SelectForm = (props) => {
  const { content, onContentChange, tooltip } = props;

  const { id, label, value, options, width } = content;

  const handleSelectChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl sx={formControlStyled(width)} key={id}>
      <InputLabel
        sx={{
          pointerEvents: "auto",
          ...(tooltip ? formStyled.labelWithTooltip : {}),
        }}
        shrink
      >
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpIcon />
          </Tooltip>
        )}
      </InputLabel>
      <Select
        value={value}
        // native
        onChange={handleSelectChange}
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

SelectForm.defaultProps = {
  tooltip: null,
};

export default SelectForm;
