import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import SelectPage from "cms/back-office/components/SelectPage";
import { formStyled } from "./ContentForm";

const SubpageForm = (props) => {
  const { content, onContentChange, withLabel, canBeEmpty } = props;

  const { id, label } = content;

  const handleSelectPage = (p) => {
    if (p && p.id) {
      const { versions, ...others } = p;
      versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
      const [lastVersion] = versions;
      if (lastVersion) {
        const pageVersion = {
          ...lastVersion,
          page: { ...others },
        };

        onContentChange({
          ...content,
          value: pageVersion,
        });
      }
    } else if (!p || !p.id) {
      onContentChange({
        ...content,
        value: "",
      });
    }
  };

  const SelectPageInput = (
    <SelectPage
      currentPage={content.value && content.value.page}
      onSelectPage={handleSelectPage}
      canBeEmpty={canBeEmpty}
      label={label}
      onlyPublishedPages
    />
  );

  if (withLabel) {
    return (
      <Box key={id} sx={{ maxWidth: 400, mb: "1rem", "& h2": formStyled.title }}>
        <h2>{label}</h2>
        {SelectPageInput}
      </Box>
    );
  }
  return SelectPageInput;
};

SubpageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  withLabel: PropTypes.bool,
  canBeEmpty: PropTypes.bool,
};

SubpageForm.defaultProps = {
  withLabel: true,
  canBeEmpty: false,
};

export default SubpageForm;
