import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import ColorForm from "cms/back-office/components/contentForms/ColorForm";
import SelectForm from "cms/back-office/components/contentForms/SelectForm";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";
import Link from "cms/editableComponents/Link";

const IconForm = (props) => {
  const { content, onContentChange } = props;

  const { id, children } = content;

  const typeChild = children.find((c) => c.key === "type");
  const iconChild = children.find((c) => c.key === "icon");
  const colorChild = children.find((c) => c.key === "color");
  const backgroundColorChild = children.find((c) => c.key === "background");

  const handleChildChange = (oldChild) => (newChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      }),
    });
  };

  return (
    <Box component="fieldset" key={id} sx={formStyled.fieldset}>
      <legend>Icône</legend>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <InputForm content={iconChild} onContentChange={handleChildChange(iconChild)} />
        </Grid>
        <Grid item xs={6}>
          <SelectForm content={typeChild} onContentChange={handleChildChange(typeChild)} />
        </Grid>
        <Grid item xs={6}>
          <ColorForm content={colorChild} onContentChange={handleChildChange(colorChild)} />
        </Grid>
        <Grid item xs={6}>
          <ColorForm content={backgroundColorChild} onContentChange={handleChildChange(backgroundColorChild)} />
        </Grid>
      </Grid>
      <Link
        url="https://fontawesome.com/icons?d=gallery&s=light"
        external
        underline="always"
        color="primary"
        sx={{ fontSize: "0.9rem" }}
      >
        Voir la liste des icônes
      </Link>
    </Box>
  );
};

IconForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default IconForm;
