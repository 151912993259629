import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo, useCallback } from "react";
import { orderBy } from "lodash";
import { withRouter } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box, Button, Grid, Input, InputAdornment, Typography, Table, TableBody, TableCell, TableSortLabel, TableRow, TableHead, TablePagination, IconButton, } from "@mui/material";
import Search from "@mui/icons-material/Search";
import Icon from "cms/editableComponents/Icon";
import Modal from "cms/back-office/components/Modal";
import Loader from "cms/back-office/components/Loader";
import { queryParamPageKey, queryParamSiteKey } from "cms/back-office/components/AdminWebsiteContext";
import AdminPageVersionsService from "cms/back-office/services/AdminPageVersionsService";
import ThemeBackOfficeProvider from "cms/back-office/theme/ThemeBackOfficeProvider";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { createQueryParams } from "cms/utils/urlUtils";
import { useAreaList } from "services/area.service";
import { useEstablishmentList } from "services/establishments.service";
import CreateEstablishment from "bo/CreateEstablishment";
import EditEstablishment from "bo/EditEstablishment";
var ManageEstablishments = function (_a) {
    var history = _a.history;
    var keycloak = useKeycloak().keycloak;
    var connectedUserId = keycloak.subject;
    var canUpdateManagerEstablishment = keycloak.hasResourceRole("update_establishment_of_manager", "arpavie-api");
    var canUpdateAreaEstablishments = keycloak.hasResourceRole("update_establishment_of_area", "arpavie-api");
    var canUpdateEstablishments = keycloak.hasResourceRole("update_establishment", "arpavie-api");
    var canCreateEstablishments = keycloak.hasResourceRole("create_establishment", "arpavie-api");
    var rowsPerPageOptions = [10, 25, 50];
    var establishmentsPagination = useEstablishmentList().data;
    var _b = (establishmentsPagination || {}).data, allEstablishments = _b === void 0 ? [] : _b;
    var areasPagination = useAreaList().data;
    var _c = (areasPagination || {}).data, areas = _c === void 0 ? [] : _c;
    var _d = React.useState([]), cmsPageVersions = _d[0], setCmsPageVersions = _d[1];
    var getPageVersions = React.useCallback(function (pageIds) {
        return AdminPageVersionsService.find({
            params: {
                join: ["page"],
                filter: ["pageId||in||".concat(pageIds.join(","))],
                per_page: 200,
            },
        }).then(setCmsPageVersions);
    }, []);
    var _e = React.useState(""), search = _e[0], setSearch = _e[1];
    var establishments = React.useMemo(function () {
        if (search.length < 3) {
            return allEstablishments;
        }
        return allEstablishments.filter(function (establishment) {
            var name = establishment.name, type = establishment.type, address = establishment.address;
            var city = address.city, street = address.street;
            return [name, type, city, street].some(function (field) { return field.toLocaleLowerCase().includes(search.toLocaleLowerCase()); });
        });
    }, [allEstablishments, search]);
    var _f = useState({
        order: "desc",
        sortBy: "id",
    }), sorting = _f[0], setSorting = _f[1];
    var _g = useState({ page: 0, rowsPerPage: 10 }), pagination = _g[0], setPagination = _g[1];
    var _h = useState(), currentEstablishment = _h[0], setCurrentEstablishment = _h[1];
    var _j = useState(false), openEstablishmentFormModal = _j[0], setOpenEstablishmentFormModal = _j[1];
    var order = sorting.order, sortBy = sorting.sortBy;
    var page = pagination.page, rowsPerPage = pagination.rowsPerPage;
    var totalRows = useMemo(function () { return (Array.isArray(establishments) ? establishments.length : 0); }, [establishments]);
    var sortedEstablishments = useMemo(function () { return (Array.isArray(establishments) ? orderBy(establishments, [sorting.sortBy], [sorting.order || "desc"]) : []); }, [establishments, sorting]);
    var currentEstablishments = useMemo(function () {
        return Array.isArray(sortedEstablishments)
            ? sortedEstablishments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : [];
    }, [page, rowsPerPage, sortedEstablishments]);
    React.useEffect(function () {
        var pageIds = currentEstablishments.map(function (establishment) { return establishment.cmsPageId; });
        if (pageIds.length > 0) {
            getPageVersions(pageIds);
        }
    }, [currentEstablishments, getPageVersions]);
    var handleSearchChange = function (event) {
        setSearch(event.target.value);
    };
    var handleChangeSort = function (event, key) {
        if (sortBy !== key) {
            setSorting({
                order: "asc",
                sortBy: key,
            });
        }
        else {
            setSorting({
                order: order === "asc" ? "desc" : "asc",
                sortBy: key,
            });
        }
    };
    var handleChangePage = useCallback(function (event, newPage) {
        setPagination({
            rowsPerPage: rowsPerPage,
            page: newPage,
        });
    }, [rowsPerPage, setPagination]);
    var handleChangeRowsPerPage = useCallback(function (event) {
        setPagination({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    }, [setPagination]);
    var handleOpenCreateEstablishmentModal = function () {
        setOpenEstablishmentFormModal(true);
    };
    var handleOpenEditEstablishmentModal = function (establishment) {
        setCurrentEstablishment(establishment);
        setOpenEstablishmentFormModal(true);
    };
    var handleCloseEstablishmentFormModal = React.useCallback(function () {
        setCurrentEstablishment();
        setOpenEstablishmentFormModal(false);
    }, []);
    var createSortHandler = function (property) { return function (event) {
        handleChangeSort(event, property);
    }; };
    var headCells = [
        { id: "name", sortable: true, label: "Nom", render: function (row) { return row.name; } },
        { id: "type", sortable: true, label: "Type", render: function (row) { return row.type; } },
        {
            id: "street",
            label: "Adresse",
            render: function (row) { var _a, _b; return "".concat((_a = row.address) === null || _a === void 0 ? void 0 : _a.streetNb, " ").concat((_b = row.address) === null || _b === void 0 ? void 0 : _b.street); },
        },
        {
            id: "address.zipCode",
            sortable: true,
            label: "Code Postal",
            render: function (row) { var _a; return "".concat((_a = row.address) === null || _a === void 0 ? void 0 : _a.zipCode); },
        },
        {
            id: "address.city",
            sortable: true,
            label: "Ville",
            render: function (row) { var _a; return "".concat((_a = row.address) === null || _a === void 0 ? void 0 : _a.city); },
        },
        {
            id: "areaid",
            sortable: true,
            label: "Territoire",
            render: function (row) { var _a; return (_a = areas.find(function (area) { return area.id === row.areaId; })) === null || _a === void 0 ? void 0 : _a.name; },
        },
        {
            id: "edit",
            label: "",
            render: function (row) {
                var cmsPageId = row.cmsPageId, establishmentAreaId = row.areaId, manager = row.manager;
                var establishmentManagerId = (manager || {}).id;
                var version = cmsPageId && cmsPageVersions.find(function (v) { return v.pageId === cmsPageId; });
                var _a = (version === null || version === void 0 ? void 0 : version.page) || {}, pageId = _a.id, siteId = _a.siteId;
                var area = areas.find(function (area) { return area.id === establishmentAreaId; });
                var responsible = (area || {}).responsible;
                var canUpdate = canUpdateEstablishments ||
                    (canUpdateAreaEstablishments && responsible === connectedUserId) ||
                    (canUpdateManagerEstablishment && establishmentManagerId === connectedUserId);
                return (_jsxs(_Fragment, { children: [canUpdate && (_jsx(IconButton, { sx: { p: 0.5, fontSize: "1.1rem" }, onClick: function () { return handleOpenEditEstablishmentModal(row); }, children: _jsx(Icon, { icon: "pen", title: "Modifier l'\u00E9tablissement" }) })), version && (_jsx(IconButton, { sx: { p: 0.5, fontSize: "1.1rem", ml: 1 }, onClick: function () {
                                var _a;
                                history.push("".concat(CmsHooks.getHook(HOOKS.Bo_AdminWebsitePath), "?").concat(createQueryParams((_a = {},
                                    _a[queryParamSiteKey] = siteId,
                                    _a[queryParamPageKey] = pageId,
                                    _a))));
                            }, children: _jsx(Icon, { icon: "eye", title: "Voir la fiche \u00E9tablissement" }) }))] }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", sx: { mb: 2 }, children: [_jsx(Grid, { item: true, xs: "auto", children: _jsx(Input, { placeholder: "Filtrer les \u00E9tablissements", disableUnderline: true, onChange: handleSearchChange, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { size: "large", children: _jsx(Search, {}) }) }) }) }), canCreateEstablishments && (_jsx(Grid, { item: true, xs: "auto", children: _jsxs(Button, { sx: {
                                borderRadius: "5px",
                                flex: 1,
                            }, color: "secondary", variant: "contained", size: "small", onClick: handleOpenCreateEstablishmentModal, children: [_jsx(Icon, { icon: "hotel" }), _jsx(Box, { sx: { ml: 1 }, children: "Nouvel \u00E9tablissement" })] }) }))] }), !establishments && (_jsx(Box, { sx: { my: 2 }, children: _jsx(Loader, {}) })), establishments && establishments.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: headCells.map(function (headCell) { return (_jsx(TableCell, { sx: { fontWeight: 600, fontSize: "1rem" }, children: _jsxs(TableSortLabel, { hideSortIcon: !headCell.sortable, active: sortBy === headCell.id, direction: order, sx: {
                                                color: "inherit",
                                                lineHeight: "normal",
                                            }, onClick: headCell.sortable ? createSortHandler(headCell.id) : undefined, children: [headCell.label, sortBy === headCell.id ? (_jsx(Box, { component: "span", sx: {
                                                        border: 0,
                                                        clip: "rect(0 0 0 0)",
                                                        height: 1,
                                                        m: -1,
                                                        overflow: "hidden",
                                                        p: 0,
                                                        position: "absolute",
                                                        top: 20,
                                                        width: 1,
                                                    }, children: order === "desc" ? "sorted descending" : "sorted ascending" })) : null] }) }, headCell.id)); }) }) }), _jsx(TableBody, { children: currentEstablishments.map(function (data) { return (_jsx(TableRow, { children: headCells.map(function (attribut, index) { return (_jsx(TableCell, { children: attribut.render(data) }, "".concat(attribut + index.toString()))); }) }, data.id)); }) })] }), pagination !== null && (_jsx(TablePagination, { labelRowsPerPage: "\u00C9tablissements par page", rowsPerPageOptions: rowsPerPageOptions, component: "div", count: totalRows, rowsPerPage: rowsPerPage, page: page, backIconButtonProps: {
                            "aria-label": "Pages suivantes",
                        }, nextIconButtonProps: {
                            "aria-label": "Pages précédentes",
                        }, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelDisplayedRows: function () { return "Page ".concat(page + 1, " sur ").concat(Math.ceil(totalRows / rowsPerPage)); } }))] })) : (establishments && (_jsx(Typography, { variant: "h5", sx: { fontSize: "1rem", fontWeight: 600, my: 2 }, children: "Aucun \u00E9tablissement trouv\u00E9" }))), _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeBackOfficeProvider, { children: _jsx(Modal, { open: openEstablishmentFormModal, size: "md", title: "".concat(currentEstablishment ? "Modifier" : "Ajouter", " un \u00E9tablissement"), onClose: handleCloseEstablishmentFormModal, children: currentEstablishment ? (_jsx(EditEstablishment, { establishment: currentEstablishment, onValidate: handleCloseEstablishmentFormModal, onCancel: handleCloseEstablishmentFormModal })) : (_jsx(CreateEstablishment, { onValidate: handleCloseEstablishmentFormModal, onCancel: handleCloseEstablishmentFormModal })) }) }) })] }));
};
export default withRouter(ManageEstablishments);
