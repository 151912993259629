var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import { useFeatureList } from "services/feature.service";
import SelectForm from "cms/back-office/components/contentForms/SelectForm";
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Grid, FormHelperText } from "@mui/material";
import { getDefaultContentByType, generateContentId } from "cms/back-office/utils/adminContentsUtils";
import customTypes from "customComponents/customTypes";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";
var ErrorMessage = function () {
    return (_jsx(FormHelperText, { sx: { mt: 2 }, error: true, children: "Vous ne pouvez pas s\u00E9lectionner plus de 4 services" }));
};
var FeaturesForm = function (_a) {
    var content = _a.content, onContentChange = _a.onContentChange;
    var id = content.id, label = content.label, children = content.children;
    var featureStatus = {
        INCLUDED: "included",
        AVAILABLE: "available",
        NOT_AVAILABLE: "not_available",
    };
    var featuresCategory = children.find(function (c) { return c.key === "category"; });
    var includedFeatures = children.find(function (c) { return c.key === featureStatus.INCLUDED; });
    var availableFeatures = children.find(function (c) { return c.key === featureStatus.AVAILABLE; });
    var pagination = useFeatureList({
        params: {
            per_page: 200,
        },
    }).data;
    var features = (pagination || {}).data;
    var _b = React.useState(false), error = _b[0], setError = _b[1];
    var handleChangeCategory = function (newChild) {
        setError(false);
        onContentChange(__assign(__assign({}, content), { children: children.map(function (c) {
                if (c.key === "category") {
                    return newChild;
                }
                if (c.key === featureStatus.INCLUDED || c.key === featureStatus.AVAILABLE) {
                    return __assign(__assign({}, c), { children: [] });
                }
                return c;
            }) }));
    };
    var getFeatureStatus = function (featureName) {
        var _a, _b;
        var isIncluded = (_a = includedFeatures === null || includedFeatures === void 0 ? void 0 : includedFeatures.children) === null || _a === void 0 ? void 0 : _a.find(function (child) { var _a, _b; return ((_b = (_a = child === null || child === void 0 ? void 0 : child.children) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.key === "name"; })) === null || _b === void 0 ? void 0 : _b.value) === featureName; });
        if (isIncluded)
            return featureStatus.INCLUDED;
        var isAvailable = (_b = availableFeatures === null || availableFeatures === void 0 ? void 0 : availableFeatures.children) === null || _b === void 0 ? void 0 : _b.find(function (child) { var _a, _b; return ((_b = (_a = child === null || child === void 0 ? void 0 : child.children) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.key === "name"; })) === null || _b === void 0 ? void 0 : _b.value) === featureName; });
        if (isAvailable)
            return featureStatus.AVAILABLE;
        return featureStatus.NOT_AVAILABLE;
    };
    var selectFeature = function (feature, newStatus) {
        onContentChange(__assign(__assign({}, content), { children: children.map(function (c) {
                var _a;
                if (c.key === newStatus) {
                    var defaultContent = getDefaultContentByType(customTypes.FEATURE);
                    var newFeatureContent = __assign(__assign({}, defaultContent), { children: (_a = defaultContent === null || defaultContent === void 0 ? void 0 : defaultContent.children) === null || _a === void 0 ? void 0 : _a.map(function (c) { return (__assign(__assign({}, c), { value: feature[c.key] })); }) });
                    c.children.push(__assign({ key: "feature" }, generateContentId(newFeatureContent)));
                }
                return c;
            }) }));
    };
    var deselectFeature = function (feature, currentStatus) {
        var _a;
        var currentFeaturesGroup = currentStatus === featureStatus.INCLUDED ? includedFeatures : availableFeatures;
        var newFeatures = (_a = currentFeaturesGroup === null || currentFeaturesGroup === void 0 ? void 0 : currentFeaturesGroup.children) === null || _a === void 0 ? void 0 : _a.filter(function (child) { var _a, _b; return ((_b = (_a = child === null || child === void 0 ? void 0 : child.children) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.key === "name"; })) === null || _b === void 0 ? void 0 : _b.value) !== feature.name; });
        onContentChange(__assign(__assign({}, content), { children: children.map(function (c) {
                if (c.key === currentStatus) {
                    c.children = newFeatures;
                }
                return c;
            }) }));
    };
    var handleChangeFeatureStatus = function (feature) { return function (e) {
        var _a, _b;
        setError(false);
        var newStatus = e.target.value;
        var currentStatus = getFeatureStatus(feature.name);
        if (newStatus === featureStatus.INCLUDED || newStatus === featureStatus.AVAILABLE) {
            if (currentStatus === featureStatus.INCLUDED || currentStatus === featureStatus.AVAILABLE) {
                deselectFeature(feature, currentStatus);
                selectFeature(feature, newStatus);
            }
            else if (((_a = includedFeatures === null || includedFeatures === void 0 ? void 0 : includedFeatures.children) === null || _a === void 0 ? void 0 : _a.length) + ((_b = availableFeatures === null || availableFeatures === void 0 ? void 0 : availableFeatures.children) === null || _b === void 0 ? void 0 : _b.length) < 4) {
                selectFeature(feature, newStatus);
            }
            else {
                setError(true);
            }
        }
        else {
            deselectFeature(feature, currentStatus);
        }
    }; };
    return (_jsxs(Fragment, { children: [_jsx(Box, { component: "h2", sx: formStyled.title, children: label }), _jsx(SelectForm, { content: featuresCategory, onContentChange: handleChangeCategory }), error && _jsx(ErrorMessage, {}), _jsx(Grid, { container: true, spacing: 3, sx: { mt: 0, px: 1 }, children: features === null || features === void 0 ? void 0 : features.map(function (feature) {
                    return (feature.category === featuresCategory.value && (_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(FormControl, { children: [_jsx(FormLabel, { id: feature.id, children: feature.name }), _jsxs(RadioGroup, { "aria-labelledby": feature.id, name: "controlled-radio-buttons-group", value: getFeatureStatus(feature.name), onChange: handleChangeFeatureStatus(feature), children: [_jsx(FormControlLabel, { value: featureStatus.INCLUDED, control: _jsx(Radio, {}), label: "Inclus" }), _jsx(FormControlLabel, { value: featureStatus.AVAILABLE, control: _jsx(Radio, {}), label: "Compl\u00E9mentaire" }), _jsx(FormControlLabel, { value: featureStatus.NOT_AVAILABLE, control: _jsx(Radio, {}), label: "Ne pas mettre en avant" })] })] }) }, feature.id)));
                }) }), error && _jsx(ErrorMessage, {})] }, id));
};
export default FeaturesForm;
