var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useQuery } from "react-query";
import { TextField, Box, Button, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import UserService from "cms/back-office/services/UserService";
import { renderKeycloakUserName } from "utils/keycloakUtils";
var getAreaDto = function (area) {
    var _a = area || {}, name = _a.name, responsible = _a.responsible;
    return {
        name: name,
        responsible: responsible,
    };
};
var getFormValue = function (_a) {
    var type = _a.type, value = _a.value, checked = _a.checked;
    switch (type) {
        case "number":
            return value !== "" ? Number(value) : null;
        case "checkbox":
            return Boolean(checked);
        default:
            return value;
    }
};
var AreaForm = function (props) {
    var area = props.area, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var _a = useQuery(["keycloakgroups"], function () { return UserService.getGroups(); }).data, groups = _a === void 0 ? [] : _a;
    var relaiComGroup = React.useMemo(function () { return groups.find(function (group) { return group.name === "relai_com"; }); }, [groups]);
    var _b = useQuery(["keycloakusersgroup", relaiComGroup === null || relaiComGroup === void 0 ? void 0 : relaiComGroup.id], function () {
        if (relaiComGroup === null || relaiComGroup === void 0 ? void 0 : relaiComGroup.id) {
            return UserService.getGroupUsers(relaiComGroup === null || relaiComGroup === void 0 ? void 0 : relaiComGroup.id).then(function (users) { return users.filter(function (user) { return user.enabled; }); });
        }
        return new Promise(function (resolve) { return resolve([]); });
    }, {
        enabled: Boolean(relaiComGroup === null || relaiComGroup === void 0 ? void 0 : relaiComGroup.id),
    }).data, relaiComUsers = _b === void 0 ? [] : _b;
    var _c = React.useState(getAreaDto(area)), form = _c[0], setForm = _c[1];
    var _d = React.useState(false), formSubmitted = _d[0], setFormSubmitted = _d[1];
    var name = form.name, responsibleId = form.responsible;
    var responsible = React.useMemo(function () { return relaiComUsers.find(function (user) { return user.id === responsibleId; }); }, [relaiComUsers, responsibleId]);
    React.useEffect(function () {
        setForm(getAreaDto(area));
    }, [area]);
    var handleFormChange = function (e) {
        var _a;
        var name = e.target.name;
        setForm(__assign(__assign({}, form), (_a = {}, _a[name] = getFormValue(e.target) || null, _a)));
    };
    var isValidName = !!name;
    var isFormValid = isValidName;
    var handleSubmit = function (event) {
        event.preventDefault();
        setFormSubmitted(true);
        if (isFormValid) {
            onSubmit(form);
        }
    };
    var handleCancel = function () {
        onCancel();
    };
    return (_jsx(_Fragment, { children: _jsxs("form", { onSubmit: handleSubmit, children: [_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 8, children: _jsx(TextField, { label: "Nom", value: name, name: "name", onChange: handleFormChange, error: !isValidName && formSubmitted, helperText: !isValidName && formSubmitted && "Champ obligatoire" }) }), _jsx(Grid, { item: true, xs: 12, md: 8, children: _jsxs(FormControl, { children: [_jsx(InputLabel, { children: "Responsable" }), _jsxs(Select, { value: responsibleId || "", name: "responsible", onChange: handleFormChange, sx: { width: "100%" }, displayEmpty: true, renderValue: function () {
                                            if (!responsible) {
                                                return _jsx("em", { children: "Nom du responsable" });
                                            }
                                            return renderKeycloakUserName(responsible);
                                        }, children: [responsible && _jsx(MenuItem, { value: "", children: "Aucun" }), Object.values(relaiComUsers).map(function (user) { return (_jsx(MenuItem, { value: user.id, children: renderKeycloakUserName(user) }, user.id)); })] })] }) })] }), _jsxs(Box, { sx: {
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                    }, children: [_jsx(Button, { color: "secondary", sx: { mr: 3 }, onClick: handleCancel, children: "Annuler" }), _jsx(Button, { type: "submit", color: "primary", children: "Enregistrer" })] })] }) }));
};
export default AreaForm;
