var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useQueryClient } from "react-query";
import AdminContext from "cms/back-office/components/AdminContext";
import EstablishmentForm from "bo/EstablishmentForm";
import { useEstablishmentUpdate, entityName } from "services/establishments.service";
var EditEstablishment = function (props) {
    var establishment = props.establishment, onValidate = props.onValidate, onCancel = props.onCancel;
    var id = establishment.id;
    var _a = React.useContext(AdminContext), displaySuccess = _a.displaySuccess, displayError = _a.displayError;
    var queryClient = useQueryClient();
    var updateEstablishment = useEstablishmentUpdate({
        onSuccess: function () {
            queryClient.invalidateQueries(entityName);
            displaySuccess("Établissement mis à jour");
            onValidate();
        },
        onError: function () {
            displayError("Erreur lors de la modification de l'établissement");
        },
    }).mutate;
    var handleValidateEstablishment = function (values) {
        updateEstablishment(__assign(__assign({}, values), { id: id }));
    };
    return _jsx(EstablishmentForm, { establishment: establishment, onSubmit: handleValidateEstablishment, onCancel: onCancel });
};
export default EditEstablishment;
