import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SelectPage from "cms/back-office/components/SelectPage";
import contentsTypes from "cms/enums/contentsTypes";
import InputForm from "cms/back-office/components/contentForms/InputForm";
import CheckboxForm from "cms/back-office/components/contentForms/CheckboxForm";
import { formStyled, formControlStyled } from "cms/back-office/components/contentForms/ContentForm";

const linkTypes = {
  page: "page",
  url: "url",
};

const LinkForm = (props) => {
  const { content, onContentChange, disabled, formSubmitted } = props;

  const { id, label, children, width } = content;

  const pageChild = children.find((c) => c.type === contentsTypes.PAGE);
  const urlChild = children.find((c) => c.type === contentsTypes.URL);
  const titleUrlChild = children.find((c) => c.type === contentsTypes.INPUT);
  const checkboxChild = children.find((c) => c.type === contentsTypes.CHECKBOX);

  const [linkType, setLinkType] = useState(pageChild.value ? linkTypes.page : linkTypes.url);

  useEffect(() => {
    setLinkType(pageChild.value ? linkTypes.page : linkTypes.url);
  }, [pageChild.value]);

  const handleLinkTypeChange = (e) => {
    const type = e.target.value;
    setLinkType(type);
  };

  const handleSelectPage = (p) => {
    const { versions, ...others } = p;
    // sort version by updated (last modified first)
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      onContentChange({
        ...content,
        children: children.map((child) => {
          // Update page value
          if (child === pageChild) {
            return {
              ...child,
              value: {
                ...lastVersion,
                page: { ...others },
              },
            };
          }
          // Set url empty when a page is selected
          if (child === urlChild) {
            return {
              ...child,
              value: "",
            };
          }
          return child;
        }),
      });
    }
  };

  const handleUrlChange = (newUrlChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        // Set page empty when a page is selected
        if (child === pageChild) {
          return {
            ...child,
            value: "",
          };
        }
        // Update url value
        if (child === urlChild) {
          return newUrlChild;
        }
        return child;
      }),
    });
  };

  const handleCheckboxChange = (newCheckboxChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === checkboxChild) {
          return newCheckboxChild;
        }
        return child;
      }),
    });
  };

  const handleTitleUrlChange = (newTitleUrlChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === titleUrlChild) {
          return newTitleUrlChild;
        }
        return child;
      }),
    });
  };

  return (
    <Box component="fieldset" sx={formStyled.fieldset} key={id}>
      <legend>{label}</legend>
      <FormControl>
        <FormLabel component="legend">Type de lien</FormLabel>
        <RadioGroup value={linkType} onChange={handleLinkTypeChange} row>
          <FormControlLabel
            value={linkTypes.url}
            control={<Radio color="secondary" disabled={disabled} />}
            label="Url"
          />
          <FormControlLabel
            value={linkTypes.page}
            control={<Radio color="secondary" disabled={disabled} />}
            label="Page"
          />
        </RadioGroup>
      </FormControl>
      {linkType === linkTypes.url ? (
        <InputForm
          content={urlChild}
          onContentChange={handleUrlChange}
          regexValidation={urlChild.regexValidation}
          formSubmitted={formSubmitted}
        />
      ) : (
        <FormControl sx={formControlStyled(width)}>
          <SelectPage
            currentPage={pageChild.value && pageChild.value.page}
            onSelectPage={handleSelectPage}
            disabled={disabled}
            label={pageChild.label}
          />
        </FormControl>
      )}
      <InputForm
        content={titleUrlChild}
        onContentChange={handleTitleUrlChange}
        placeholder="Titre du lien"
        formSubmitted={formSubmitted}
      />
      <CheckboxForm content={checkboxChild} onContentChange={handleCheckboxChange} />
    </Box>
  );
};

LinkForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formSubmitted: PropTypes.bool,
};

LinkForm.defaultProps = {
  disabled: false,
  formSubmitted: false,
};

export default LinkForm;
