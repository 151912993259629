import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "cms/components/TextField";
import { formControlStyled } from "cms/back-office/components/contentForms/ContentForm";

const TextAreaForm = (props) => {
  const { content, onContentChange, formSubmitted, regexValidation, ...others } = props;

  const { id, label, value, width } = content;

  const handleTextareaChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl sx={formControlStyled(width)} key={id}>
      <TextField
        label={label}
        value={value}
        onChange={handleTextareaChange}
        multiline
        rows={3}
        sx={{
          "& > * > textarea": {
            resize: "vertical",
          },
        }}
        {...others}
      />
    </FormControl>
  );
};

TextAreaForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  regexValidation: PropTypes.string,
};

TextAreaForm.defaultProps = {
  formSubmitted: false,
  regexValidation: null,
};

export default TextAreaForm;
