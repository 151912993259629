import React, { useState, createRef, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const AutoResizeInput = (props) => {
  const { value, onBlur, onChange, debounce, ...others } = props;

  const [showInput, setShowInput] = useState(false);

  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    setLocalValue(inputValue);
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }
    setCurrentTimeOut(
      setTimeout(() => {
        onChange(inputValue);
      }, debounce),
    );
  };

  const inputRef = createRef();

  useEffect(() => {
    if (inputRef.current && showInput) {
      inputRef.current.focus();
    }
  }, [showInput, inputRef]);

  const handleSizerClick = (e) => {
    e.stopPropagation();
    setShowInput(true);
  };

  const handleInputBlur = (e) => {
    setShowInput(false);
    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

  return (
    <Fragment>
      <Box
        component="span"
        onClick={handleSizerClick}
        sx={{
          display: showInput ? "none" : "inline-block",
          width: "100%",
          border: "1px dashed #999",
          minHeight: "1em",
          cursor: "text",
        }}
      >
        <pre>{localValue}</pre>
      </Box>
      <Box
        component="textarea"
        {...others}
        onChange={handleChange}
        sx={{
          display: showInput ? "inline-block" : "none",
          color: "inherit",
        }}
        value={localValue}
        ref={inputRef}
        onBlur={handleInputBlur}
      />
    </Fragment>
  );
};

AutoResizeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  debounce: PropTypes.number,
};

AutoResizeInput.defaultProps = {
  value: "",
  onBlur: null,
  debounce: 2000,
};

export default AutoResizeInput;
