import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import AdminTemplateSettingsContext from "cms/back-office/components/AdminTemplateSettingsContext";

const AdminTemplateSettingsActions = (props) => {
  const { children, ...others } = props;

  const { setDisplayAdminTemplateSettingsModal } = useContext(AdminTemplateSettingsContext);

  return (
    <Box sx={{ position: "relative", border: "double #2646D4" }} {...others}>
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          left: "12px",
          zIndex: "50",
          bgcolor: "#ffffff66",
          display: "flex",
          borderRadius: "50%",
          p: 0.25,
          cursor: "pointer",
        }}
      >
        <Icon onClick={() => setDisplayAdminTemplateSettingsModal(true)} icon="cog" type="fas" />
      </Box>
      {children}
    </Box>
  );
};

AdminTemplateSettingsActions.propTypes = {
  children: PropTypes.node.isRequired,
};

AdminTemplateSettingsActions.defaultProps = {};

export default AdminTemplateSettingsActions;
