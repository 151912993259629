import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Icon from "cms/editableComponents/Icon";
import { formStyled } from "cms/back-office/components/contentForms/ContentForm";
import { getAllDefaultContents } from "cms/back-office/utils/adminContentsUtils";
import { ThemeContext } from "cms/back-office/theme/ThemeBackOfficeProvider";
import { getCategoryLabel } from "cms/enums/categoriesContents";

const AvailableContentsPreview = () => {
  const { themeBackOffice: theme } = useContext(ThemeContext);

  const groupedContents = {};

  getAllDefaultContents()
    .filter((c) => c.Preview)
    .sort((a, b) => (a.label < b.label ? -1 : 1))
    .forEach((content) => {
      const { category } = content;
      groupedContents[category] = groupedContents[category] || [];
      groupedContents[category].push(content);
    });

  return Object.keys(groupedContents).map((category) => (
    <React.Fragment key={category}>
      <Box component="h2" sx={formStyled.title}>
        {getCategoryLabel(category)}
      </Box>
      {groupedContents[category].map((content) => {
        const { label, Preview, icon, IconComponent, type } = content;

        let contentIcon = null;
        if (icon) {
          contentIcon = <Icon icon={icon} />;
        } else if (IconComponent) {
          contentIcon = (
            <IconComponent
              color={theme.palette.layout.contentBtnBackground}
              backgroundColor={theme.palette.layout.contentBtnColor}
            />
          );
        }

        return (
          <Box mb={8} key={type} sx={{ "& h2": { display: "flex", ...formStyled.title } }}>
            <h2>
              {contentIcon}
              {contentIcon && <span>&nbsp;&nbsp;</span>}
              {label}
            </h2>
            <Preview />
          </Box>
        );
      })}
    </React.Fragment>
  ));
};

export default AvailableContentsPreview;
